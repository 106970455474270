import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "@/services/client";
import _ from "lodash";
import { calculateMedian } from "@/helpers/helpers";
import { setActiveEditMenuLayerId, resetAllFilters } from "./root-slice";

const initialState = {
  id: null,
  name: null,
  layers: [],
  zoom_links: [],
  activeLayerIndex: null,
  max_zoom_link_count: 0,
  show_stats: false,
  map: null,
  map_page: null,
};

const convertLayerPointCellsToHash = (layerPoint) => {
  const newCells = layerPoint.cells.reduce((cells, currentCell) => {
    return { ...cells, [currentCell.field_id]: currentCell };
  }, {});
  return { ...layerPoint, cells: newCells };
};

const convertLayerPointToHash = (layer) => {
  const newLayerPoints = layer.layer_points.reduce(
    (layerPoints, currentLayerPoint) => {
      const newLayerPoint = convertLayerPointCellsToHash(currentLayerPoint);
      return { ...layerPoints, [newLayerPoint.id]: newLayerPoint };
    },
    {},
  );
  return { ...layer, layer_points: newLayerPoints };
};

const calculateFieldStats = (layer, field) => {
  const newField = { ...field };
  if (field.show_stats) {
    const cellValues = Object.values(layer.layer_points).map((layerPoint) => {
      return layerPoint.cells[field.id]?.value;
    });
    const floatValues = cellValues
      .filter((v) => v !== undefined)
      .map(parseFloat);
    switch (field.stats_type) {
      case "sum_type":
        newField.sum = _.sum(floatValues);
        break;
      case "avg_type":
        newField.avg = _.mean(floatValues);
        break;
      case "min_type":
        newField.min = _.min(floatValues);
        break;
      case "max_type":
        newField.max = _.max(floatValues);
        break;
      case "median_type":
        newField.median = calculateMedian(floatValues);
        break;
      case "range_type":
        newField.min = _.min(floatValues);
        newField.max = _.max(floatValues);
        break;
      default:
        break;
    }
  }
  return newField;
};

const calculateLayerFieldStates = (layer) => {
  const newFields = layer.fields.map((field) =>
    calculateFieldStats(layer, field),
  );
  return { ...layer, fields: newFields };
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject: (state, action) => {
      const project = action.payload;
      const result = {
        ...state,
        ...project,
      };
      if (project.layers) {
        result.layers = project.layers
          .map(convertLayerPointToHash)
          .map(calculateLayerFieldStates);
      }
      return result;
    },
    addLayer: (state, action) => {
      const layer = action.payload;
      return { ...state, layers: [...state.layers, layer] };
    },
    setLayer: (state, action) => {
      const { layerId, updatedData } = action.payload;

      // Convert LayerPoints array to hash
      const updatedLayer = calculateLayerFieldStates(
        convertLayerPointToHash(updatedData),
      );

      const updatedLayers = state.layers.map((layer) =>
        layer.id === Number(layerId) ? { ...layer, ...updatedLayer } : layer,
      );

      return { ...state, layers: updatedLayers };
    },
    setLayerWithoutConversion: (state, action) => {
      const { layerId, updatedData } = action.payload;

      const updatedLayers = state.layers.map((layer) =>
        layer.id === Number(layerId) ? { ...layer, ...updatedData } : layer,
      );
      return { ...state, layers: updatedLayers };
    },
    deleteLayer: (state, action) => {
      const layerIdToDelete = action.payload;
      const updatedLayers = state.layers.filter(
        (layer) => layer.id !== layerIdToDelete,
      );
      return { ...state, layers: updatedLayers };
    },
    setActiveLayerIndexAction: (state, action) => {
      return { ...state, activeLayerIndex: action.payload };
    },
    addLayerPoint: (state, action) => {
      const { layerId, newLayerPoint } = action.payload;
      newLayerPoint.cells = {};
      const updatedLayers = state.layers.map((layer) =>
        layer.id === Number(layerId)
          ? {
              ...layer,
              layer_points: {
                ...layer.layer_points,
                [newLayerPoint.id]: newLayerPoint,
              },
            }
          : layer,
      );

      return { ...state, layers: updatedLayers };
    },
    addFieldToActiveLayer: (state, action) => {
      const { field } = action.payload;
      const activeLayer = state.layers[state.activeLayerIndex];
      activeLayer.fields = [...activeLayer.fields, field];
    },
    updateFieldsOfCurrentLayer: (state, action) => {
      const { newFields } = action.payload;
      const activeLayer = state.layers[state.activeLayerIndex];
      let updatedLayer = { ...activeLayer, fields: newFields };
      updatedLayer = calculateLayerFieldStates(updatedLayer);
      const updatedLayers = state.layers.map((layer) =>
        layer.id === Number(updatedLayer.id) ? updatedLayer : layer,
      );
      return { ...state, layers: updatedLayers };
    },
    deleteFieldOfCurrentLayer: (state, action) => {
      const { fieldId } = action.payload;
      const activeLayer = state.layers[state.activeLayerIndex];
      if (fieldId === activeLayer.text_marker_id) {
        activeLayer.text_marker_id = null;
      }
      activeLayer.fields = activeLayer.fields.filter(
        (field) => field.id !== fieldId,
      );
    },
    clearFieldOfCurrentLayer: (state, action) => {
      const { fieldId } = action.payload;
      const activeLayer = state.layers[state.activeLayerIndex];
      Object.values(activeLayer.layer_points).forEach((layerPoint) => {
        // eslint-disable-next-line no-param-reassign
        delete layerPoint.cells[fieldId];
      });
    },
    addZoomLink: (state, action) => {
      const { zoomLink } = action.payload;
      state.zoom_links.push(zoomLink);
    },
    removeZoomLink: (state, action) => {
      const { zoomLink } = action.payload;
      return {
        ...state,
        zoom_links: state.zoom_links.filter((link) => link.id !== zoomLink.id),
      };
    },
    setCell: (state, action) => {
      const { layerPointId, cell } = action.payload;

      const activeLayer = state.layers[state.activeLayerIndex];
      const activeLayerPoint = activeLayer.layer_points[layerPointId];
      const updatedLayer = {
        ...activeLayer,
        layer_points: {
          ...activeLayer.layer_points,
          [layerPointId]: {
            ...activeLayerPoint,
            cells: {
              ...activeLayerPoint.cells,
              [cell.field_id]: cell,
            },
          },
        },
      };
      const fields = activeLayer.fields.map((field) =>
        field.id === cell.field_id
          ? calculateFieldStats(updatedLayer, field)
          : field,
      );
      const updatedLayers = state.layers.map((layer) =>
        layer.id === Number(updatedLayer.id)
          ? { ...updatedLayer, fields }
          : layer,
      );

      return { ...state, layers: updatedLayers };
    },
    clearState: () => {
      return { ...initialState };
    },
  },
});

export const {
  setProject,
  addLayer,
  setLayer,
  deleteLayer,
  setActiveLayerIndexAction,
  addLayerPoint,
  setCell,
  addFieldToActiveLayer,
  updateFieldsOfCurrentLayer,
  deleteFieldOfCurrentLayer,
  clearFieldOfCurrentLayer,
  addZoomLink,
  removeZoomLink,
  setLayerWithoutConversion,
  setStatsBoardVisibility,
  clearState,
} = projectSlice.actions;

// Async thunk to fetch layer
export const fetchLayer = (layerId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/layers/${layerId}`);
    dispatch(setLayer({ layerId, updatedData: response }));
  } catch (error) {
    toast.error(`Error fetching layer: ${error.message}`);
  }
};

// tempdata should be removed when backend is ready
export const updateLayer = (layer) => async (dispatch) => {
  try {
    const response = await axios.patch(`/api/layers/${layer.id}`, {
      layer: {
        ...layer,
        id: undefined,
      },
    });
    const updatedLayer = response;
    dispatch(
      setLayerWithoutConversion({
        layerId: layer.id,
        updatedData: { ...updatedLayer },
      }),
    );
    dispatch(setActiveEditMenuLayerId(false));
  } catch (error) {
    toast.error(`Error updating layer: ${error.message}`);
  }
};

export const updateFieldApi = (field) => async (dispatch) => {
  try {
    const payload = {
      field: {
        ...field,
        id: undefined,
      },
    };
    const newFields = await axios.patch(`/api/fields/${field.id}`, payload);
    dispatch(updateFieldsOfCurrentLayer({ newFields }));
  } catch (error) {
    toast.error(`Error updating field: ${error.message}`);
  }
};

export const updateProject = (project) => async (dispatch) => {
  try {
    const response = await axios.patch(`/api/projects/${project.id}`, {
      project: {
        ...project,
      },
    });
    const updatedProject = response;
    dispatch(setProject(updatedProject));
  } catch (error) {
    toast.error(`Error updating project: ${error.message}`);
  }
};

export const setActiveLayerIndex = (payload) => (dispatch) => {
  dispatch(resetAllFilters());
  dispatch(setActiveLayerIndexAction(payload));
};

export default projectSlice.reducer;
