/* global window */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import { FIELD_TYPE_NAMES, DEFAULT_MAP_STYLES } from "@/constants";
import MapLayerControl from "../layers/MapLayerControl";
import MapLayerStatsControl from "../layers/MapLayerStatsControl";
import MapQuickZoomControl from "../layers/map-quick-zoom/MapQuickZoomControl";
import MapQuickZoomLinksList from "../layers/map-quick-zoom/MapQuickZoomLinksList";
import styles from "../styles.module.css";
import Marker from "../layers/Marker";
import MapLayerPointCard from "../layers/MapLayerPointCard";
import MapLayerFiltersControl from "../layers/filters/MapLayerFiltersControl";
import MapThemeControl from "./MapThemeControl";

const defaultMapOptions = {
  zoomControlOptions: {
    position: window.google.maps.ControlPosition.LEFT_TOP,
    style: "SMALL",
  },
  fullscreenControl: false,
  styles: JSON.parse(DEFAULT_MAP_STYLES.json),
};
const defaultZoom = 13;

let layerMarkers = [];

function ProjectMap() {
  const mapCenter = useSelector((state) => state.root.mapCenter);
  const [googleMap, setGoogleMap] = useState(null);
  const [mapOptions, setMapOptions] = useState(defaultMapOptions);
  const [mapZoom, setMapZoom] = useState(defaultZoom);
  const [layersCoordinates, setLayersCoordinates] = useState([]);
  const [activeAnchorPoint] = useState(null);
  const layers = useSelector((state) => state.project.layers);
  const coordinateFieldType = useSelector((state) =>
    state.root.fieldTypes.find(
      (fieldType) => fieldType.name === FIELD_TYPE_NAMES.COORDINATES,
    ),
  );
  const filters = useSelector((state) => state.root.filters);
  const projectMapStyles = useSelector((state) => state.project.map);

  const isVisible = (filter, value) => {
    if (filter.type === FIELD_TYPE_NAMES.SELECT) {
      if (filter.selected.includes(value)) {
        return true;
      }
      return false;
    }
    if (filter.type === FIELD_TYPE_NAMES.NUMBER) {
      const number = parseFloat(value);
      if (
        (filter.range.min && number < filter.range.min) ||
        (filter.range.max && number > filter.range.max)
      ) {
        return false;
      }
      return true;
    }
    return true;
  };

  useEffect(() => {
    if (coordinateFieldType.id) {
      const coordinates = [];
      layers.forEach((layer, layerIndex) => {
        if (!layer || !layer.fields || layer.hidden) {
          return;
        }
        const { fields } = layer;
        const coordinateField = layer.fields.find(
          (f) => f.field_type_id === coordinateFieldType.id,
        );
        Object.keys(layer.layer_points).forEach((layerPoint) => {
          const layerIcon = layer.icon;
          const { cells } = layer.layer_points[layerPoint];
          let hiddenPoint = false;
          let layerCoordinate = null;
          fields.forEach((field) => {
            const cellKey = field.id;
            if (hiddenPoint) {
              return;
            }
            if (cells[cellKey]) {
              const fieldId = cells[cellKey].field_id;
              if (filters[fieldId]) {
                if (!isVisible(filters[fieldId], cells[cellKey].value)) {
                  hiddenPoint = true;
                }
              }
              if (fieldId === coordinateField.id) {
                const [lng, lat] = cells[cellKey].value.split(", ");
                layerCoordinate = {
                  layerPointId: cells[cellKey].layer_point_id,
                  layerId: layer.id,
                  layerIndex,
                  key: cells[cellKey].id,
                  lng: Number(lng),
                  lat: Number(lat),
                  icon: layerIcon,
                };
              }
            } else if (filters[cellKey]) {
              hiddenPoint = true;
            }
          });
          if (!hiddenPoint && layerCoordinate) {
            coordinates.push(layerCoordinate);
          }
        });
      });
      setLayersCoordinates(coordinates);
    }
  }, [layers, filters, coordinateFieldType]);

  const statsBoardVisible = useSelector((state) => state.project.show_stats);

  const calculateBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();

    if (!layersCoordinates.length) {
      return null;
    }

    layersCoordinates.forEach((coordinates) => {
      bounds.extend(
        new window.google.maps.LatLng(coordinates.lat, coordinates.lng),
      );
    });

    return bounds;
  };

  const fitBounds = () => {
    const bounds = calculateBounds();
    if (bounds && googleMap) {
      googleMap.fitBounds(bounds);
      if (googleMap.getZoom() > 17) {
        setMapZoom(17);
      }
    } else {
      setMapZoom(4);
    }
  };

  const handleMapLoaded = (params) => {
    setGoogleMap(params.map);
    // drawZones()
    // showLegendOnMap()
    fitBounds();
  };

  const clearAllLayerMarkers = () => {
    layerMarkers.forEach((marker) => {
      marker.setMap(null);
    });
    layerMarkers = [];
  };

  const onStyleSelected = (newStyles) => {
    setMapOptions({ ...mapOptions, styles: newStyles });
  };

  useEffect(() => {
    if (googleMap) {
      clearAllLayerMarkers();
    }
  }, [layersCoordinates.length]);

  useEffect(() => {
    fitBounds();
    setMapOptions({
      ...mapOptions,
      styles: projectMapStyles
        ? JSON.parse(JSON.parse(projectMapStyles).json)
        : mapOptions.styles,
    });
  }, [googleMap]);

  return (
    <div
      className={styles.mapContainer}
      style={{
        width: activeAnchorPoint
          ? "calc(100% - var(--side-bar-width))"
          : "100%",
      }}
    >
      {statsBoardVisible && (
        <div className="layer-stats-control-container">
          <MapLayerStatsControl />
        </div>
      )}
      <div className="map-layer-control-container">
        <MapLayerControl />
      </div>
      <div className="map-layer-filters-container">
        <MapLayerFiltersControl />
      </div>
      {googleMap && (
        <div className="map-quick-zoom-control-container">
          <MapQuickZoomControl map={googleMap} />
        </div>
      )}
      <div className="map-theme-control-container">
        <MapThemeControl onStyleSelected={onStyleSelected} />
      </div>
      <div className="map-quick-zoom-link-list-control-container">
        <MapQuickZoomLinksList setMapZoom={setMapZoom} />
      </div>
      <div className="map-layer-point-card-container">
        <MapLayerPointCard />
      </div>
      <GoogleMapReact
        options={mapOptions}
        center={mapCenter}
        zoom={mapZoom}
        defaultZoom={defaultZoom}
        onGoogleApiLoaded={(params) => handleMapLoaded(params)}
      >
        {layersCoordinates.map((coordinates) => (
          <Marker
            key={coordinates.key}
            lat={coordinates.lat}
            lng={coordinates.lng}
            icon={coordinates.icon}
            layerPointId={coordinates.layerPointId}
            layerIndex={coordinates.layerIndex}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}

export default ProjectMap;
