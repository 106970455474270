import React from "react";
import { Link } from "react-router-dom";

function StaticPrivacy() {
  return (
    <div className="row">
      <div className="col">
        <h1>Privacy</h1>
        <p>
          At Zonegoat, we collect and manage user data according to the
          following Privacy Policy. This document is part of Zonegoat’ Terms of
          Service, and by using zonegoat.com (the “Website”). Please read the{" "}
          <Link to="/terms">Terms of Service</Link> in their entirety, and refer
          to those for definitions and contacts.
        </p>
        <p>
          At Zonegoat, we use the "Privacy by Design" approach. This means that
          we think of privacy implication before building a new feature, we
          don't ask for information we don't need, and we protect the
          information we have. We take pride in treating our users' privacy the
          way we'd like to be treated by a website like Zonegoat, as
          individuals.
        </p>

        <h4>Data Collected</h4>
        <p>
          We collect anonymous data from every visitor of the Website to monitor
          traffic and fix bugs. For example, we collect information like web
          requests, the data sent in response to such requests, the Internet
          Protocol address, the browser type, the browser language, and a
          timestamp for the request.
        </p>
        <p>
          We ask you to log in and provide certain personal information (such as
          your name and email address) in order to be able to save your profile
          and the documents and comments associated with it. In order to enable
          these or any other login based features, we use cookies to store
          session information for your convenience. You can block or delete
          cookies and still be able to use Zonegoat, although if you do you will
          then be asked for your username and password every time you log in to
          the Website. Your decision to utilize our features and provide your
          data will always be voluntary.
        </p>
        <p>
          You are able to view, change and remove your data associated with your
          profile and organization. Should you choose to delete your account,
          please contact us at support@zonegoat.com and we will follow up with
          such request as soon as possible. Minors and children should not use
          Zonegoat. By using the Website, you represent that you have the legal
          capacity to enter into a binding agreement.
        </p>

        <h4>Use of the Data</h4>
        <p>
          We only use your personal information to provide you the Zonegoat
          services or to communicate with you about the services or the Website.
        </p>
        <p>
          With respect to any information you may choose to share with Zonegoat,
          we take the privacy and confidentiality of such information seriously.
          If you choose to make your information public, we recommend you redact
          any and all references to people and addresses, as we can't protect
          public data and we are not responsible for any violation of privacy
          law you may be liable for. We employ industry standard techniques to
          protect against unauthorized access of data about you that we store,
          including personal information. We do not share personal information
          you have provided to us without your consent, unless: * doing so is
          appropriate to carry out your own request; * we believe it's needed to
          enforce our Terms of Service, or that is legally required; * we
          believe it's needed to detect, prevent or address fraud, security or
          technical issues; * otherwise protect our property, legal rights, or
          that of others. Zonegoat is operated from the United States and
          Canada. If you are visiting the Website from outside the U.S or
          Canada., you agree to any processing of any personal information you
          provide us according to this policy.
        </p>
        <p>
          Zonegoat may contact you, by email or other means. For example,
          Zonegoat may send you promotional emails relating to Zonegoat or other
          third parties Zonegoat feels you would be interested in, or
          communicate with you about your use of the Zonegoat website. Zonegoat
          may also use technology to alert us via a confirmation email when you
          open an email from us. You can modify your email notification
          preferences by clicking the appropriate link included in the footer of
          email notifications. If you do not want to receive email from
          Zonegoat, please opt out of receiving emails at the bottom of any
          Zonegoat emails or by editing your profile preferences.
        </p>

        <h4>Sharing of Data</h4>
        <p>
          We don't share your personal information with third parties. Only
          aggregated, anonymized data is periodically transmitted to external
          services to help us improve the Zonegoat Website and service. We
          currently use Google Analytics (traffic analysis, SEO optimization),
          Mailgun (mailing list management), Crisp (email support) and Mixpanel
          (analytics). We listed below what data these third parties extract
          exactly. Feel free to check out their own Privacy Policies to find out
          more. * Google Analytics: anonymous (ad serving domains, browser type,
          demographics, language settings, page views, time/date), pseudonymous
          (IP address) * Mailgun: name and email of our beta subscription list *
          Crisp: anonymous * Mixpanel: anonymous, pseudonymous, registered
          users' activity.
        </p>
        <p>
          We also use social buttons provided by services like Twitter, Google+,
          LinkedIn and Facebook. Your use of these third party services is
          entirely optional. We are not responsible for the privacy policies
          and/or practices of these third party services, and you are
          responsible for reading and understanding those third party services’
          privacy policies.
        </p>
        <p>
          We employ and contract with people and other entities that perform
          certain tasks on our behalf and who are under our control (our
          “Agents”). We may need to share personal information with our Agents
          in order to provide products or services to you. Unless we tell you
          differently, our Agents do not have any right to use Personal
          Information or other information we share with them beyond what is
          necessary to assist us. You hereby consent to our sharing of Personal
          Information with our Agents.
        </p>
        <p>
          We may choose to buy or sell assets. In these types of transactions,
          user information is typically one of the transferred business assets.
          Moreover, if we, or substantially all of our assets, were acquired, or
          if we go out of business or enter bankruptcy, user information would
          be one of the assets that is transferred or acquired by a third party.
          You acknowledge that such transfers may occur, and that any acquirer
          of us or our assets may continue to use your personal information as
          set forth in this policy.
        </p>

        <h4>Changes to the Privacy Policy</h4>
        <p>
          We may amend this Privacy Policy from time to time. Use of information
          we collect now is subject to the Privacy Policy in effect at the time
          such information is used. If we make major changes in the way we
          collect or use information, we will notify you by posting an
          announcement on the Website or sending you an email. In any case, you
          can track all changes via the TOS Tracker. A user is bound by any
          changes to the Privacy Policy when he or she uses the Services after
          such changes have been first posted. Should you have any question or
          concern, please write to support@zonegoat.com.
        </p>
      </div>
    </div>
  );
}

export default StaticPrivacy;
