import React from "react";

function NotFound() {
  return (
    <div className="rails-default-error-page">
      <div className="dialog">
        <div>
          <h1 className="text-cetner">404</h1>
          <h3>The page you were looking for doesn't exist.</h3>
          <p>You may have mistyped the address or the page may have moved.</p>
        </div>
        <p>
          If you are the application owner, check the logs for more information.
        </p>
      </div>
      <style>
        {`
          .rails-default-error-page {
            background-color: #EFEFEF;
            height: 100%;
            display: flex;
            align-items: center;
          }

          .rails-default-error-page div.dialog {
            width: 95%;
            max-width: 33em;
            margin: 0em auto 0;
          }

          .rails-default-error-page div.dialog > div {
            border: 1px solid #CCC;
            border-right-color: #999;
            border-left-color: #999;
            border-bottom-color: #BBB;
            border-top: #B00100 solid 4px;
            border-top-left-radius: 9px;
            border-top-right-radius: 9px;
            background-color: white;
            padding: 7px 12% 0;
            box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);
          }

          .rails-default-error-page h1 {
            color: #730E15;
            text-align: center;
          }

          .rails-default-error-page div.dialog > p {
            margin: 0 0 1em;
            padding: 1em;
            background-color: #F7F7F7;
            border: 1px solid #CCC;
            border-right-color: #999;
            border-left-color: #999;
            border-bottom-color: #999;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-top-color: #DADADA;
            color: #666;
            box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);
            font-size: 12px;
          }
        `}
      </style>
    </div>
  );
}

export default NotFound;
