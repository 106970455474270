import React from "react";
import { getIconURL } from "@/helpers/helpers";

function LayerIcon({ icon, style }) {
  return (
    <img
      className="layer-icon"
      src={getIconURL(icon)}
      alt={icon}
      style={style}
      width={24}
    />
  );
}

export default LayerIcon;
