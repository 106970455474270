import React from "react";

function StaticDislaimer() {
  return (
    <div className="row">
      <div className="col">
        <h3>Dislaimer</h3>
        <p>
          All our listings are aggregated from public listings and then filtered
          by our algorithm. While every attempt is made to keep zoning
          information accurate and up to date, Zonegoat does not accept any
          responsability for the accuracy of this information. Any reliance upon
          any materials on this site or on site linked to this site shall be at
          your sole risk. Accordingly, verification of actual zoning for a
          particular property or properties must be obtained by way of a Zoning
          Verification Certificate from a local government. All information
          should be verified independently before being used or relied upon.
        </p>
      </div>
    </div>
  );
}

export default StaticDislaimer;
