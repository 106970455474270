import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownDivider from "react-bootstrap/DropdownDivider";

function SelectInput({
  value,
  options,
  onSelected,
  onCreate,
  row,
  column,
  onEditingEnded,
}) {
  const [searchValue, setSearchValue] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showDropdown, setShowDropdown] = useState(false);
  const [hasExactMatch, setHasExactMatch] = useState(false);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filterOptions = () => {
    if (searchValue.trim() === "") {
      setFilteredOptions(options);
      setHasExactMatch(false);
    } else {
      const filtered = options.filter((option) =>
        option.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setFilteredOptions(filtered);
      setHasExactMatch(filtered.includes(searchValue));
    }
  };

  const onOptionClick = (option) => {
    onSelected(option);
    setSearchValue(option);
    setShowDropdown(false);
  };

  const createOption = () => {
    if (!hasExactMatch) {
      onCreate(searchValue);
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    filterOptions();
  }, [options, searchValue]);

  const handleToggle = () => {
    setTimeout(() => {
      onEditingEnded();
    }, 1);
  };

  return (
    <>
      <input
        className="celled-input"
        value={searchValue}
        onChange={handleChange}
        onFocus={() => setShowDropdown(true)}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      <Dropdown
        show={showDropdown}
        className="zonegoat-dropdown select-input-dropdown"
        onToggle={handleToggle}
      >
        <Dropdown.Menu style={{ width: 300 }}>
          <Dropdown.Item
            className="pr-0 pl-2 text-left"
            style={{ opacity: 1, fontSize: 15 }}
            disabled
          >
            <small className="w-100 fw-semibold">
              Select or create an option
            </small>
          </Dropdown.Item>

          {filteredOptions.map((option, index) => (
            <Dropdown.Item
              className="pl-0"
              // eslint-disable-next-line react/no-array-index-key
              key={`row-${row.id}-col-${column.id}-select-option-${option}-${index}`}
              onClick={() => onOptionClick(option)}
            >
              <span
                className="badge text-zonegoat-bg-warning ml-2"
                style={{ borderRadius: 4 }}
              >
                {option}
              </span>
            </Dropdown.Item>
          ))}
          {searchValue.trim() && !hasExactMatch && (
            <>
              <DropdownDivider />
              <Dropdown.Item className="pl-2" onClick={createOption}>
                <span className="fw-semibold" style={{ fontSize: 17 }}>
                  Create
                </span>
                <span
                  className="badge text-zonegoat-bg-warning ml-2"
                  style={{ borderRadius: 4 }}
                >
                  {searchValue}
                </span>
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default SelectInput;
