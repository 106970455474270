import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownDivider from "react-bootstrap/DropdownDivider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDeleteLeft,
  faGripVertical,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import axios from "@/services/client";
import { updateProject, deleteProject } from "@/store/root-slice";
import ZGButton from "@/components/ZGButton";
import TextInput from "../../TextInput";

const ActionMenu = React.forwardRef(
  (
    {
      children,
      style,
      className,
      "aria-labelledby": labeledBy,
      confirmProjectName,
      onValidStateChange,
      projectName,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div>
          <TextInput
            label="Project name"
            placeholder="Enter name of the project"
            className="form-control mt-1 fw-normal"
            onConfirm={confirmProjectName}
            onValidStateChange={onValidStateChange}
            value={projectName}
          />
        </div>
        {children}
      </div>
    );
  },
);

function ProjectActionMenu({ project }) {
  const [showMenu, setShowMenu] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [autoClose, setAutoClose] = useState("outside");
  const dispatch = useDispatch();
  const editProjectDropdownRef = useRef(null);

  const handleToggle = () => setShowMenu(!showMenu);

  const confirmProjectName = async (newProjectName) => {
    if (newProjectName === project.name) {
      return;
    }
    dispatch(
      updateProject({
        id: project.id,
        name: newProjectName,
      }),
    ).then(() => {
      handleToggle();
    });
  };

  const onValidStateChange = (isValid) => {
    if (!isValid) {
      setAutoClose(false);
    } else {
      setAutoClose("outside");
    }
  };

  const onDeleteProject = async () => {
    try {
      setIsDeleting(true);
      setAutoClose(false);
      await axios.delete(`/api/projects/${project.id}`);
      setIsDeleting(false);
      setAutoClose("outside");
      dispatch(deleteProject(project.id));
    } catch (error) {
      toast.error(`Error deleting Project: ${error.message}`);
      setIsDeleting(false);
      setAutoClose("outside");
    }
  };

  return (
    <>
      <Dropdown
        show={showMenu}
        onToggle={handleToggle}
        autoClose={autoClose}
        ref={editProjectDropdownRef}
        className="zonegoat-dropdown"
      >
        <Dropdown.Menu
          as={ActionMenu}
          projectName={project.name}
          confirmProjectName={confirmProjectName}
          onValidStateChange={onValidStateChange}
          style={{ padding: 7, width: 270, left: 25, top: 8 }}
        >
          <DropdownDivider />

          <Dropdown.Item
            className="d-flex align-items-center p-0 cursor-pointer"
            onClick={onDeleteProject}
            disabled={isDeleting}
          >
            <span className="dropdown-icon-container">
              <FontAwesomeIcon
                className="p-0"
                icon={isDeleting ? faSpinner : faDeleteLeft}
              />
            </span>
            <span className="pl-2">Delete Project</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <ZGButton
        className="btn btn-icon btn-sm text-zonegoat-gray-2"
        onClick={() => setShowMenu(true)}
      >
        <FontAwesomeIcon icon={faGripVertical} />
      </ZGButton>
    </>
  );
}

export default ProjectActionMenu;
