import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "@/services/client";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { DEFAULT_MAP_STYLES } from "@/constants";
import { updateProject } from "@/store/project-slice";
import { useDispatch, useSelector } from "react-redux";

const PAGE_SIZE = 8;

function MapThemeControl({ onStyleSelected }) {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const projectMapStyles = useSelector((state) =>
    JSON.parse(state.project.map),
  );
  const [showMenu, setShowMenu] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(project.map_page || 1);
  const [snazzyStyles, setSnazzyStyles] = useState({ styles: [] });

  const resetStyles = () => {
    onStyleSelected(JSON.parse(DEFAULT_MAP_STYLES.json));
    dispatch(
      updateProject({
        id: project.id,
        map: JSON.stringify(DEFAULT_MAP_STYLES),
        map_page: 1,
      }),
    );
  };

  useEffect(() => {
    const fetchSnazzyStyles = async () => {
      try {
        setIsLoadingPage(true);
        const response = await axios.get(
          `api/snazzy_maps?pageSize=${PAGE_SIZE}&page=${currentPage}`,
        );
        setIsLoadingPage(false);
        setSnazzyStyles(response);
      } catch (error) {
        toast.error("Error fetching snazzy styles:", error);
      }
    };

    fetchSnazzyStyles();
  }, [currentPage]);

  const handleToggle = () => setShowMenu(!showMenu);

  const onPageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleStyleCardClicked = (mapStyle) => {
    onStyleSelected(JSON.parse(mapStyle.json));
    dispatch(
      updateProject({
        id: project.id,
        map: JSON.stringify(mapStyle),
        map_page: currentPage,
      }),
    );
  };

  return (
    <div>
      <button
        type="button"
        className="map-theme-control-toggle-button btn btn-icon"
        onClick={() => setShowMenu(true)}
      >
        <FontAwesomeIcon style={{ fontSize: 20 }} icon={faMap} />
      </button>
      <Dropdown
        show={showMenu}
        onToggle={handleToggle}
        className="zonegoat-dropdown"
        style={{ bottom: 440 }}
      >
        <Dropdown.Menu className="map-theme-control-dropdown-menu">
          <div className="d-flex align-items-end justify-content-between w-100">
            <div>
              <h6 className="fw-bold text-zonegoat-gray">Base Map</h6>
              <p
                className="fs-bold text-zonegoat-gray m-0"
                style={{ fontSize: 12 }}
              >
                Choose a style to personalize your base map.
              </p>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-sm btn-text text-zonegoat-primary"
                onClick={resetStyles}
              >
                Reset
              </button>
            </div>
          </div>
          <div className="map-theme-style-dropdown-body">
            <div className="map-theme-style-cards-container">
              {isLoadingPage
                ? Array.from({ length: 8 }, (_, index) => index + 1).map(
                    (element) => (
                      <div
                        key={`map-style-${element}`}
                        className="map-theme-style-card"
                        style={{ backgroundColor: "#efefef" }}
                      />
                    ),
                  )
                : snazzyStyles.styles.map((mapStyle) => (
                    <button
                      type="button"
                      key={`map-style-${mapStyle.id}`}
                      className="map-theme-style-card"
                      style={{
                        backgroundImage: `url(${mapStyle.imageUrl})`,
                        border:
                          projectMapStyles?.id === mapStyle.id
                            ? "3px solid #000"
                            : 0,
                      }}
                      onClick={() => handleStyleCardClicked(mapStyle)}
                    />
                  ))}
            </div>
          </div>
          <Stack spacing={2}>
            <Pagination
              size="small"
              count={
                snazzyStyles.pagination ? snazzyStyles.pagination.totalPages : 1
              }
              page={currentPage}
              defaultPage={1}
              boundaryCount={2}
              onChange={onPageChange}
            />
          </Stack>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default MapThemeControl;
