import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "@/services/client";

const initialState = {
  projects: [],
  fieldTypes: [],
  activeEditMenuLayerId: null,
  draftColumnId: null,
  activeCell: null,
  mapCenter: { lat: 42.33, lng: -83.05 },
  markerLayerPointId: null,
  markerLayerIndex: null,
  filters: {},
};

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setFieldTypes: (state, action) => {
      const fieldTypesHash = {};
      action.payload.forEach((fieldType) => {
        fieldTypesHash[fieldType.id] = fieldType.name;
      });
      return { ...state, fieldTypes: action.payload, fieldTypesHash };
    },
    setActiveEditMenuLayerId: (state, action) => {
      return { ...state, activeEditMenuLayerId: action.payload };
    },
    setDraftColumnId: (state, action) => {
      return { ...state, draftColumnId: action.payload };
    },
    setActiveCell: (state, action) => {
      return { ...state, activeCell: action.payload };
    },
    setMarkerIds: (state, action) => {
      return { ...state, ...action.payload };
    },
    setProjects: (state, action) => {
      return { ...state, projects: action.payload };
    },
    addProject: (state, action) => {
      const { project } = action.payload;
      return { ...state, projects: [...state.projects, project] };
    },
    setMapCenter: (state, action) => {
      const mapCenter = action.payload;
      return { ...state, mapCenter };
    },
    setProject: (state, action) => {
      const { id, updatedProject } = action.payload;
      const updatedProjects = state.projects.map((project) =>
        project.id === id ? { ...project, ...updatedProject } : project,
      );
      return { ...state, projects: updatedProjects };
    },
    setFilter: (state, action) => {
      const { key, value } = action.payload;
      const { filters } = state;
      filters[key] = value;
    },
    resetFilter: (state, action) => {
      const { key } = action.payload;
      const { filters } = state;
      delete filters[key];
    },
    resetAllFilters: (state) => {
      return { ...state, filters: {} };
    },
    deleteProject: (state, action) => {
      const projectIdToDelete = action.payload;
      const updatedProjects = state.projects.filter(
        (project) => project.id !== projectIdToDelete,
      );
      return { ...state, projects: updatedProjects };
    },
  },
});

export const {
  setFieldTypes,
  setDraftColumnId,
  setActiveEditMenuLayerId,
  setActiveCell,
  setMarkerIds,
  setProjects,
  addProject,
  setProject,
  deleteProject,
  setMapCenter,
  setFilter,
  resetFilter,
  resetAllFilters,
} = rootSlice.actions;

export const updateProject = (project) => async (dispatch) => {
  try {
    const response = await axios.patch(`/api/projects/${project.id}`, {
      project: {
        ...project,
      },
    });
    const updatedProject = response;
    dispatch(setProject({ id: project.id, updatedProject }));
  } catch (error) {
    toast.error(`Error updating project: ${error.message}`);
  }
};

export default rootSlice.reducer;
