/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable jsx-a11y/label-has-associated-control
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FIELD_TYPE_NAMES } from "@/constants";

function NumberFieldFilter({ field, onChange }) {
  const storeRange = useSelector((state) => state.root.filters[field.id]);
  const [range, setRange] = useState(
    storeRange?.range || { min: null, max: null },
  );
  const [error, setError] = useState("");

  const handleChnage = (key, value) => {
    const newRange = { ...range, [key]: value ? Number(value) : null };

    // Check for validity
    if (
      newRange.min !== null &&
      newRange.max !== null &&
      newRange.min > newRange.max
    ) {
      setError("Min cannot be greater than Max");
    } else {
      setRange(newRange);
      onChange({
        key: field.id,
        value: { type: FIELD_TYPE_NAMES.NUMBER, range: newRange },
      });
      setError("");
    }
  };

  const clearFilter = () => {
    setRange({ min: null, max: null });
    setError("");
    onChange({
      key: field.id,
      value: undefined,
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <p className="text-zonegoat-gray-1 mb-0" style={{ fontSize: 14 }}>
          {field.name}
        </p>
        <button
          type="button"
          className="btn btn-sm btn-text text-zonegoat-primary"
          onClick={clearFilter}
          style={{ fontSize: 10 }}
        >
          Clear
        </button>
      </div>
      <div className="mb-2 mt-3 d-flex align-items-end">
        <div>
          <label
            htmlFor={`number-filter-min-${field.id}`}
            className="form-label text-zonegoat-gray-1 mb-1"
            style={{ fontSize: 12 }}
          >
            Min
          </label>
          <input
            type="number"
            id={`number-filter-min-${field.id}`}
            className="form-control p-1 number-filter-input"
            style={{ borderColor: "#000" }}
            value={range.min || ""}
            onChange={(event) => handleChnage("min", event.target.value)}
            placeholder="No Min"
          />
        </div>
        <div style={{ paddingBottom: 6, marginRight: 10, marginLeft: 10 }}>
          -
        </div>
        <div>
          <label
            htmlFor={`number-filter-max-${field.id}`}
            className="form-label text-zonegoat-gray-1 mb-1"
            style={{ fontSize: 12 }}
          >
            Max
          </label>
          <input
            type="number"
            id={`number-filter-max-${field.id}`}
            className="form-control p-1 number-filter-input"
            style={{ borderColor: "#000" }}
            value={range.max || ""}
            onChange={(event) => handleChnage("max", event.target.value)}
            placeholder="No Max"
          />
        </div>
      </div>
      {error && (
        <small className="text-danger fw-semibold" style={{ fontSize: 10 }}>
          {error}
        </small>
      )}
    </div>
  );
}

export default NumberFieldFilter;
