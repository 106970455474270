import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import TableCell from "./TableCell";
import TableRowActionMenu from "./TableRowActionMenu";
import AddInPlaceRowButton from "./AddInPlaceRowButton";

function TableRow({ rowItem, columns, index, moveRow, isDragEnabled }) {
  const ref = useRef(null);
  const [isHovering, setIsHovering] = useState(false);

  const [{ handlerId }, drop] = useDrop({
    accept: "ROW",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      /* eslint-disable-next-line */
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "ROW",
    item: () => {
      return { id: rowItem.id };
    },
    canDrag: isDragEnabled,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  const rowActionStyle = {
    cursor: isDragEnabled ? "grab" : "pointer",
    visibility: isHovering ? "visible" : "hidden",
    opacity: 0.4,
  };
  return (
    <tr
      style={{ opacity }}
      className="postion-relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      ref={ref}
    >
      <td className="row-actions-td" data-handler-id={handlerId}>
        <AddInPlaceRowButton rowActionStyle={rowActionStyle} row={rowItem} />
        <TableRowActionMenu rowActionStyle={rowActionStyle} row={rowItem} />
      </td>
      <td className="text-center">{rowItem.row_number}</td>
      {columns.map((column) => (
        <TableCell key={`cell-${column.id}`} column={column} row={rowItem} />
      ))}
      <td />
    </tr>
  );
}

export default TableRow;
