import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icons from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import ZGButton from "@/components/ZGButton";
import { setDraftColumnId } from "@/store/root-slice";
import { addFieldToActiveLayer } from "@/store/project-slice";
import axios from "@/services/client";
import { toast } from "react-toastify";
import { FIELD_TYPE_NAMES } from "@/constants";

const availableFieldTypes = [
  FIELD_TYPE_NAMES.TEXT,
  FIELD_TYPE_NAMES.SELECT,
  FIELD_TYPE_NAMES.URL,
  FIELD_TYPE_NAMES.CHECKBOX,
  FIELD_TYPE_NAMES.NUMBER,
  FIELD_TYPE_NAMES.DATE,
];

function AddNewColumnMenu() {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef(null);

  const fieldTypes = useSelector((state) => state.root.fieldTypes);
  const activeLayer = useSelector(
    (state) => state.project.layers[state.project.activeLayerIndex],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setIsOverflowing(
        container.offsetWidth + container.getBoundingClientRect().left >
          // eslint-disable-next-line no-undef
          window.innerWidth,
      );
    }
  }, [showMenu]);

  const handleToggle = () => setShowMenu(!showMenu);

  const addColumn = async (fieldType) => {
    try {
      const payload = {
        field: {
          name: fieldType.name,
          field_type_id: fieldType.id,
          layer_id: activeLayer.id,
        },
      };
      const newField = await axios.post("/api/fields", payload);
      dispatch(addFieldToActiveLayer({ field: newField }));
      dispatch(setDraftColumnId(newField.id));
    } catch (error) {
      toast.error("Error creating cell");
    }
  };

  const handleSelectedFieldType = (fieldType) => {
    addColumn(fieldType);
  };

  return (
    <span>
      <Dropdown
        show={showMenu}
        onToggle={handleToggle}
        className="zonegoat-dropdown d-inline"
      >
        <Dropdown.Menu
          ref={containerRef}
          style={{
            padding: 7,
            width: 300,
            transform: isOverflowing ? "translateX(-275px)" : "initial",
          }}
        >
          <Dropdown.Item
            className="pr-0 pl-2 text-left"
            style={{ opacity: 1 }}
            disabled
          >
            <small className="w-100">
              <b>Add Field</b>
            </small>
          </Dropdown.Item>

          {fieldTypes.map((fieldType) => (
            <Dropdown.Item
              className="d-flex align-items-center p-0 cursor-pointer"
              key={`field-type-option-${fieldType.id}`}
              disabled={!availableFieldTypes.includes(fieldType.name)}
              onClick={() => handleSelectedFieldType(fieldType)}
            >
              <span className="dropdown-icon-container left-aligned">
                <FontAwesomeIcon className="p-0" icon={icons[fieldType.icon]} />
              </span>
              <span className="pl-2">{fieldType.name}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <ZGButton
        className="btn btn-icon btn-sm"
        onClick={() => setShowMenu(true)}
      >
        <FontAwesomeIcon icon={icons.faPlus} />
      </ZGButton>
    </span>
  );
}

export default AddNewColumnMenu;
