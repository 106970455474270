import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { formatDate } from "@/helpers/helpers";
import styles from "../styles.module.css";
import ProjectActionMenu from "./ProjectActionMenu";

function ProjectsTable({ loading, projects }) {
  return (
    <table
      className={`fw-semibold text-zonegoat-gray-1 projects-table ${styles.table} ${loading ? styles.loading : ""}`}
    >
      <thead style={{ fontSize: 14 }}>
        <tr>
          <th style={{ width: 70 }} />
          <th>PROJECT</th>
          <th style={{ width: 153 }} className="text-center">
            CREATED AT
          </th>
          <th style={{ width: 300 }} />
        </tr>
      </thead>
      <tbody>
        <tr className={styles.loader}>
          <td>
            <FontAwesomeIcon icon={faSpinner} size="xl" spin />
          </td>
        </tr>
        {loading && !projects.length ? (
          <tr className="bg-white">
            <td className="text-center" colSpan={4}>
              Loading projects...
            </td>
          </tr>
        ) : (
          projects.map((project) => (
            <tr className="bg-white" key={`project-row-${project.id}`}>
              <td>
                <ProjectActionMenu project={project} />
              </td>
              <td>{project.name}</td>
              <td>{formatDate(project.created_at)}</td>
              <td>
                <Link
                  className="btn btn-warning btn-md w-100 py-2-5"
                  to={`/projects/${project.id}/`}
                >
                  VIEW PROJECT
                </Link>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}

export default ProjectsTable;
