import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { updateLayer, setActiveLayerIndex } from "@/store/project-slice";
import LayerIcon from "./LayerIcon";
import Switch from "./Switch";

function MapLayerControl() {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const layers = useSelector((state) => state.project.layers);
  const activeLayer = useSelector(
    (state) => state.project.layers[state.project.activeLayerIndex],
  );

  const handleToggle = () => setShowMenu(!showMenu);

  const handleSwitchClick = (layer) => {
    dispatch(updateLayer({ id: layer.id, hidden: !layer.hidden }));
  };

  const handleLayerChange = (index) => {
    dispatch(setActiveLayerIndex(index));
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-icon bg-white rounded-circle"
        style={{ height: 40, width: 40, border: "1px solid #cccccc" }}
        onClick={() => setShowMenu(true)}
      >
        <LayerIcon
          icon={activeLayer.icon}
          style={{ width: 26, marginLeft: -5 }}
        />
      </button>
      <Dropdown
        show={showMenu}
        onToggle={handleToggle}
        className="zonegoat-dropdown"
      >
        <Dropdown.Menu className="map-layer-control-dropdown-menu">
          <h6 className="fw-bold text-zonegoat-gray">Layers</h6>
          <p className="fs-bold text-zonegoat-gray" style={{ fontSize: 12 }}>
            Manage visibility and settings of the layers.
          </p>
          {layers.map((layer, index) => (
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ marginBottom: "17px" }}
              key={`map-control-layer-${layer.id}`}
            >
              <span>
                <span
                  className="d-inline-block text-center"
                  style={{ width: 30 }}
                >
                  <LayerIcon icon={layer.icon} />
                </span>
                <span
                  className={`${activeLayer.id === layer.id ? "text-primary" : "text-secondary"} pl-2 cursor-pointer`}
                  style={{
                    color: "#606060",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                    opacity: layer.hidden ? 0.5 : 1,
                  }}
                  role="button"
                  tabIndex={index + 3}
                  onClick={() => handleLayerChange(index)}
                  onKeyPress={(e) =>
                    e.key === "Enter" && handleLayerChange(index)
                  }
                >
                  {layer.name}
                </span>
              </span>
              <Switch
                className="h-21"
                checked={!layer.hidden}
                onChange={() => handleSwitchClick(layer)}
              />
            </div>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default MapLayerControl;
