import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassLocation } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setMapCenter } from "@/store/root-slice";

function MapQuickZoomLinksList({ setMapZoom }) {
  const dispatch = useDispatch();
  const zoomLinks = useSelector((state) => state.project.zoom_links);

  const onQuickZoomClick = (zoomLink) => {
    dispatch(setMapCenter({ lat: zoomLink.latitude, lng: zoomLink.longitude }));
    setMapZoom(zoomLink.zoom_level);
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      {zoomLinks &&
        zoomLinks.map((zoomLink) => (
          <button
            type="button"
            className="btn btn-success btn-zonegoat-green mr-3 rounded-0 px-3"
            style={{ fontSize: 13, paddingTop: 5, paddingBottom: 5 }}
            key={`map-quick-zoom-link-${zoomLink.id}`}
            onClick={() => onQuickZoomClick(zoomLink)}
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlassLocation}
              className="text-zonegaot-yellow"
            />{" "}
            {zoomLink.name}
          </button>
        ))}
    </div>
  );
}

export default MapQuickZoomLinksList;
