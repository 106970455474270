import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { updateProject } from "@/store/project-slice";

function ToggleStatsBoardButton() {
  const dispatch = useDispatch();
  const { id, show_stats: showStats } = useSelector((state) => state.project);

  const toggleVisibility = () => {
    dispatch(updateProject({ id, show_stats: !showStats }));
  };

  return (
    <button
      type="button"
      className="btn btn-outline-secondary text-zonegoat-gray"
      style={{ borderColor: "rgb(0,0,0, 26%)" }}
      onClick={toggleVisibility}
    >
      {showStats ? (
        <FontAwesomeIcon className="mr-2" icon={faEyeSlash} />
      ) : (
        <FontAwesomeIcon className="mr-2" icon={faEye} />
      )}
      Stats Board
    </button>
  );
}

export default ToggleStatsBoardButton;
