import React from "react";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { parseConfigs, formatNumber } from "@/helpers/helpers";
import { useSelector, useDispatch } from "react-redux";
import { FIELD_TYPE_NAMES, DATE_FIELD_FORMATES } from "@/constants";

import { setMarkerIds } from "@/store/root-slice";

function CardField({ field, layerPoint }) {
  const currentFieldType = useSelector((state) =>
    state.root.fieldTypes.find(
      (fieldType) => fieldType.id === field.field_type_id,
    ),
  );
  const cellId = Object.keys(layerPoint.cells).find(
    (cellKey) => layerPoint.cells[cellKey].field_id === field.id,
  );
  const cell = layerPoint.cells[cellId];
  const isSelectField = currentFieldType.name === FIELD_TYPE_NAMES.SELECT;
  const isURLField = currentFieldType.name === FIELD_TYPE_NAMES.URL;
  const isCheckboxField = currentFieldType.name === FIELD_TYPE_NAMES.CHECKBOX;
  const isNumberField = currentFieldType.name === FIELD_TYPE_NAMES.NUMBER;
  const isDateField = currentFieldType.name === FIELD_TYPE_NAMES.DATE;
  const value = cell ? cell.value : "";
  const fieldConfigs = parseConfigs(field);
  let valueElement = <span className="value fw-semibold">{value}</span>;
  if (isSelectField) {
    valueElement = (
      <span
        className="badge text-zonegoat-bg-warning ml-2"
        style={{ borderRadius: 4 }}
      >
        {value}
      </span>
    );
  } else if (isURLField) {
    valueElement = (
      <a
        className="celled-link"
        href={
          value && !value.startsWith("http://") && !value.startsWith("https://")
            ? `http://${value}`
            : value
        }
        target="blank"
      >
        {value}
      </a>
    );
  } else if (isCheckboxField) {
    if (cell) {
      if (value === "true") {
        valueElement = (
          <span className="fw-semibold">
            {fieldConfigs.trueValue || "True"}
          </span>
        );
      } else {
        valueElement = (
          <span className="fw-semibold">
            {fieldConfigs.falseValue || "False"}
          </span>
        );
      }
    } else {
      valueElement = <span />;
    }
  } else if (isNumberField) {
    valueElement = formatNumber(value, fieldConfigs.format);
  } else if (
    isDateField &&
    fieldConfigs.format === DATE_FIELD_FORMATES.COMPLETE_DATE.value
  ) {
    valueElement = (
      <span className="fw-semibold">
        {format(new Date(value), "MMMM, dd yyyy")}
      </span>
    );
  }
  return (
    <div className="card-text">
      <span className="field fw-semibold">{field.name}</span>
      {valueElement}
    </div>
  );
}

function MapLayerPointCard() {
  const dispatch = useDispatch();
  const markerLayer = useSelector((state) => {
    return state.project.layers[state.root.markerLayerIndex];
  });
  const layerPoint = useSelector((state) => {
    if (
      state.root.markerLayerIndex !== null &&
      state.root.markerLayerPointId !== null
    ) {
      const layer = state.project.layers[state.root.markerLayerIndex];
      return layer.layer_points[state.root.markerLayerPointId];
    }
    return null;
  });

  const closeCard = () => {
    dispatch(
      setMarkerIds({ markerLayerPointId: null, markerLayerIndex: null }),
    );
  };

  if (layerPoint) {
    const orderedFields = markerLayer.fields
      .filter((a) => !a.hidden_on_map)
      .sort((a, b) => a.col_number - b.col_number);

    return (
      <div className="card map-layer-point-card">
        <div className="close">
          <button
            type="button"
            className="btn btn-icon btn-lg p-0 text-dark"
            onClick={closeCard}
          >
            <FontAwesomeIcon icon={faCircleXmark} size="lg" />
          </button>
        </div>
        <div className="card-body pt-0">
          {orderedFields.map((field) => (
            <CardField
              field={field}
              layerPoint={layerPoint}
              key={`layer-point-card-field-${field.name}-${field.id}`}
            />
          ))}
        </div>
      </div>
    );
  }
  return <div />;
}

export default MapLayerPointCard;
