import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setProjects } from "@/store/root-slice";
import ProjectForm from "./ProjectForm";
import ProjectsTable from "./project-table/ProjectsTable";

function Projects() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.root.projects);
  const [loadingProjects, setLoadingProjects] = useState(false);

  const fetchProjects = async () => {
    try {
      setLoadingProjects(true);
      const response = await fetch("/api/projects");
      const data = await response.json();
      dispatch(setProjects(data));
      setLoadingProjects(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching projects:", error);
      setLoadingProjects(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <>
      <div className="bg-white p-3 text-center w-100 fw-bold">
        <b>Projects</b>
      </div>
      <div className="container">
        <div className="d-flex flex-row-reverse py-3">
          <ProjectForm />
        </div>
        <hr className="m-0" />
        <ProjectsTable projects={projects} loading={loadingProjects} />
      </div>
      <ToastContainer
        position="bottom-center"
        theme="dark"
        hideProgressBar
        closeOnClick
      />
    </>
  );
}

export default Projects;
