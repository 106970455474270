/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import TextInput from "@/components/TextInput";
import { parseConfigs } from "@/helpers/helpers";

function EditSelectColumnMenuOptions({ column, onUpdate }) {
  const [columnConfigs, setColumnConfigs] = useState(
    parseConfigs(column) || {},
  );
  const [trueValue, setTrueValue] = useState("");
  const [falseValue, setFalseValue] = useState("");

  const confirmOption = (value, key) => {
    const configs = JSON.stringify({ ...columnConfigs, [key]: value });
    onUpdate({ configs }, false);
  };

  useEffect(() => {
    const configs = parseConfigs(column) || {};
    setColumnConfigs(configs);
    if (configs.trueValue !== undefined) {
      setTrueValue(configs.trueValue);
    }
    if (configs.falseValue !== undefined) {
      setFalseValue(configs.falseValue);
    }
  }, [column.configs]);

  return (
    <>
      <label className="dropdown-label" htmlFor={`yes-value-${column.id}`}>
        True Value
      </label>
      <TextInput
        onConfirm={(val) => confirmOption(val, "trueValue")}
        value={trueValue}
        onChange={setTrueValue}
        className="form-control"
        label="True Value"
        htmlId={`yes-value-${column.id}`}
        showError={false}
        required={false}
        autoFocus={false}
      />
      <label className="dropdown-label mt-2" htmlFor={`yes-value-${column.id}`}>
        False Value
      </label>
      <TextInput
        onConfirm={(val) => confirmOption(val, "falseValue")}
        value={falseValue}
        onChange={setFalseValue}
        className="form-control"
        label="False Value"
        htmlId={`false-value-${column.id}`}
        showError={false}
        required={false}
        autoFocus={false}
      />
    </>
  );
}

export default EditSelectColumnMenuOptions;
