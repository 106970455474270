export const GOOGLE_PLACE_TYPES = [
  "Accounting",
  "Airport",
  "Amusement Park",
  "Aquarium",
  "Art Gallery",
  "ATM",
  "Bakery",
  "Bank",
  "Bar",
  "Beauty Salon",
  "Bicycle Store",
  "Book Store",
  "Bowling Alley",
  "Bus Station",
  "Cafe",
  "Campground",
  "Car Dealer",
  "Car Rental",
  "Car Repair",
  "Car Wash",
  "Casino",
  "Cemetery",
  "Church",
  "City Hall",
  "Clothing Store",
  "Convenience Store",
  "Courthouse",
  "Dentist",
  "Department Store",
  "Doctor",
  "Drugstore",
  "Electrician",
  "Electronics Store",
  "Embassy",
  "Fire Station",
  "Florist",
  "Funeral Home",
  "Furniture Store",
  "Gas Station",
  "Grocery or Supermarket",
  "Gym",
  "Hair Care",
  "Hardware Store",
  "Hindu Temple",
  "Home Goods Store",
  "Hospital",
  "Insurance_agency",
  "Jewerly Store",
  "Laundry",
  "Lawyer",
  "Library",
  "Light Rail Station",
  "Liquor Store",
  "Local Government Office",
  "Locksmith",
  "Lodging",
  "Meal Delivery",
  "Meal Takeway",
  "Mosque",
  "Movie Rental",
  "Movie Theater",
  "Moving Company",
  "Museum",
  "Night Club",
  "Painter",
  "Park",
  "Parking",
  "Pet Store",
  "Pharmacy",
  "Physiotherapist",
  "Plumber",
  "Police",
  "Post Office,",
  "Primary School",
  "Real Estate Agency",
  "Restaurant",
  "Roofing Contractor",
  "RV Park",
  "School",
  "Secondary School",
  "Shoe Store",
  "Shopping Mall",
  "Spa",
  "Stadium",
  "Storage",
  "Store",
  "Subway Station",
  "Supermarket",
  "Synagogue",
  "Taxi Stand",
  "Tourist Attraction",
  "Train Station",
  "Transit Station",
  "Travel Agency",
  "University",
  "Veterinary Care",
  "Zoo",
];

export const DURATIONS = [
  "5 min",
  "10 min",
  "15 min",
  "30 min",
  "45 min",
  "1 hour",
  "1 hour 30 min",
  "2 hour",
  "3 hour",
  "4 hour",
  "5 hour",
];

export const MAX_POI_NUMBER = 2;

export const defaultLayerMarkerURL = "/location_mark_blue.svg";

export const customEmojis = [
  {
    names: ["Location Mark Red"],
    imgUrl: "/location_mark_red.svg",
    id: "location_mark_red",
  },
  {
    names: ["Location Mark Yellow"],
    imgUrl: "/location_mark_yellow.svg",
    id: "location_mark_yellow",
  },
  {
    names: ["Location Mark Green"],
    imgUrl: "/location_mark_green.svg",
    id: "location_mark_green",
  },
  {
    names: ["Location Mark Blue"],
    imgUrl: "/location_mark_blue.svg",
    id: "location_mark_blue",
  },
];

export const customEmojisIds = customEmojis.map((e) => e.id);

export const FIELD_TYPE_NAMES = {
  ADDRESS: "Address",
  COORDINATES: "Coordinates",
  TEXT: "Text",
  SELECT: "Select",
  URL: "URL",
  CHECKBOX: "Checkbox",
  NUMBER: "Number",
  DATE: "Date",
};

export const URL_REGEX =
  /^(ftp|http|https):\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]+)+([/?].*)?$/;

export const NUMBER_FORMATES = {
  NUMBER: "Number",
  NUMBER_WITH_COMMAS: "Number with commas",
  PERCENTAGE: "Percentage",
  US_DOLLARS: "US Dollar",
  CANADIAN_DOLLARS: "Canadian Dollar",
  AUSTRALIAN_DOLLARS: "Australian Dollar",
  SINGAPOOR_DOLLARS: "Singapoor Dollar",
  DANISH_KRONE: "Danish Krone",
  KORNA: "Korna",
  NORWEGIAN_KRONE: "Norwegian krone",
  HONKONG_DOALLRS: "Hong Kong Dollar",
  EURO: "Euro",
  MEXICAN_PESOS: "Mexican Pesos",
  PHILIPINES_PESOS: "Philipines Pesos",
  POUND: "Pound",
  RUBLE: "Ruble",
  RUPEE: "Rupee",
  YUAN: "Yuan",
  YEN: "Yen",
  WON: "Won",
};

export const STATS_TYPES = {
  sum_type: "Sum",
  min_type: "Min",
  max_type: "Max",
  avg_type: "Average",
  median_type: "Median",
  range_type: "Range",
};

export const DEFAULT_MAP_STYLES = {
  id: 0,
  json: JSON.stringify([
    {
      featureType: "administrative.land_parcel",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ]),
};

export const DATE_FIELD_FORMATES = {
  COMPLETE_DATE: { label: "Complete Date", value: "complete_date" },
  YEAR: { label: "Year Only", value: "yeay" },
};
