import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextInput from "@/components/TextInput";
import {
  faGripVertical,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";
import { parseOptions } from "@/helpers/helpers";

function SelectOption({ option, onDelete }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="d-flex align-items-center justify-content-between mb-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="d-flex align-items-center">
        <FontAwesomeIcon
          icon={faGripVertical}
          className="text-zonegoat-gray-2"
        />
        <span
          className="badge text-zonegoat-bg-warning ml-2"
          style={{ borderRadius: 4 }}
        >
          {option}
        </span>
      </div>
      <FontAwesomeIcon
        style={{ visibility: isHovered ? "visible" : "hidden" }}
        className="text-zonegoat-gray cursor-pointer"
        onClick={() => onDelete(option)}
        icon={faDeleteLeft}
      />
    </div>
  );
}

function EditSelectColumnMenuOptions({ column, onUpdate }) {
  const [options, setOptions] = useState(parseOptions(column));
  const [value, setValue] = useState("");

  const confirmOption = (option) => {
    const configs = JSON.stringify({ options: [option, ...options] });
    onUpdate({ configs }, false, () => {
      setValue("");
    });
  };

  const handleDeleteOption = (option) => {
    const configs = JSON.stringify({
      options: options.filter((opt) => opt !== option),
    });
    onUpdate({ configs }, false);
  };

  useEffect(() => {
    setOptions(parseOptions(column));
  }, [column.configs]);

  return (
    <>
      <TextInput
        label="Option"
        onConfirm={confirmOption}
        value={value}
        onChange={setValue}
        submitOnBlur={false}
        placeholder="Type an option to add"
        className="form-control"
      />
      <div className="my-3" style={{ cursor: "auto" }}>
        {options.map((option, index) => (
          <SelectOption
            // eslint-disable-next-line react/no-array-index-key
            key={`${column.id}-${column.name}-selection-options-${option}-${index}`}
            option={option}
            onDelete={handleDeleteOption}
          />
        ))}
      </div>
    </>
  );
}

export default EditSelectColumnMenuOptions;
