/* eslint-disable jsx-a11y/no-autofocus */
import React from "react";
import { DATE_FIELD_FORMATES } from "@/constants";
import { parseConfigs } from "@/helpers/helpers";

// Generate a list of years for the past 200 years
const currentYear = new Date().getFullYear();
const yearsList = [];
for (let year = currentYear; year >= currentYear - 200; year -= 1) {
  yearsList.push(year);
}

function DateInput({ column, row, value, onSelected, onEditingEnded }) {
  const columnConfigs = parseConfigs(column);
  const onChange = (event) => {
    onSelected(event.target.value);
  };
  const handleBlur = () => {
    onEditingEnded();
  };
  if (columnConfigs.format === DATE_FIELD_FORMATES.COMPLETE_DATE.value) {
    return (
      <input
        className="celled-input"
        type="date"
        onChange={onChange}
        value={value}
        onBlur={handleBlur}
        autoFocus
      />
    );
  }
  if (columnConfigs.format === DATE_FIELD_FORMATES.YEAR.value) {
    return (
      <select
        className="celled-input"
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        autoFocus
      >
        {yearsList.map((year) => (
          <option
            key={`date-field-${row.id}-${column.id}-year-option-${year}`}
            value={year}
          >
            {year}
          </option>
        ))}
      </select>
    );
  }
  return <div />;
}

export default DateInput;
