import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function ZGButton({ loading, children, icon, ...rest }) {
  const isLargeButton = rest.className.includes("btn-lg");
  const isSmallButton = rest.className.includes("btn-sm");

  let buttonSize;
  let cursorType;

  if (isLargeButton) {
    buttonSize = "30%";
  } else if (isSmallButton) {
    buttonSize = "20%";
  } else {
    buttonSize = "25%";
  }

  if (loading) {
    cursorType = "wait";
  } else if (rest.disabled) {
    cursorType = "not-allowed";
  } else {
    cursorType = "pointer";
  }
  const spinnerStyles = {
    visibility: !loading ? "hidden" : "visible",
    position: "absolute",
    left: "0",
    top: buttonSize,
    transform: "translate(0, -50%)",
    width: "100%",
  };

  const childrenVisibility = {
    visibility: loading ? "hidden" : "visible",
  };

  const buttonStyles = {
    cursor: cursorType,
    position: "relative",
    border: icon ? 0 : "initial",
  };

  return (
    <button
      style={buttonStyles}
      type="button"
      {...rest}
      disabled={loading || rest.disabled}
    >
      <FontAwesomeIcon icon={faSpinner} spin style={spinnerStyles} />
      <span style={childrenVisibility}>{children}</span>
    </button>
  );
}

export default ZGButton;
