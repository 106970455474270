import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Switch.css";

function Switch({ checked, onChange, className, containerClass = "" }) {
  return (
    <div
      className={`form-switch outlined-success form-check ${containerClass}`}
    >
      <input
        className={`${className} form-check-input cursor-pointer`}
        type="checkbox"
        role="switch"
        checked={checked}
        onChange={(event) => onChange(event.target.checked)}
        id="flexSwitchCheckChecked"
      />
    </div>
  );
}

export default Switch;
