import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faRectangleList,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { setFieldTypes } from "@/store/root-slice";
import axios from "@/services/client";
import { fetchLayer } from "@/store/project-slice";
import ZGButton from "@/components/ZGButton";
import TableView from "./views/TableView";
import styles from "../styles.module.css";
import HideTableFieldsMenu from "./HideTableFieldsMenu";

function LayerDetails() {
  const dispatch = useDispatch();
  const activeLayerIndex = useSelector(
    (state) => state.project.activeLayerIndex,
  );
  const activeLayer = useSelector((state) =>
    state.project.activeLayerIndex !== null
      ? state.project.layers[state.project.activeLayerIndex]
      : null,
  );

  const fetchFieldTypes = async () => {
    try {
      const response = await axios.get("/api/field_types");
      dispatch(setFieldTypes(response));
    } catch (error) {
      toast.error(`Error fetching layer: ${error.message}`);
    }
  };

  useEffect(() => {
    if (activeLayer) {
      dispatch(fetchLayer(activeLayer.id));
    }
  }, [activeLayerIndex, activeLayer?.id]);

  useEffect(() => {
    fetchFieldTypes();
  }, []);

  return (
    <div>
      {activeLayer ? (
        <div>
          <div className={styles.zonegoatContainer}>
            <div className="d-flex align-items-center justify-content-between mb-1">
              <div className="d-flex align-items-center">
                <ZGButton className="btn btn-icon pl-0">
                  <FontAwesomeIcon
                    className="text-secondary mr-1"
                    icon={faTable}
                  />{" "}
                  Table
                </ZGButton>
                <ZGButton className="btn btn-icon" hidden>
                  <FontAwesomeIcon className="text-secondary" icon={faPlus} />
                </ZGButton>
              </div>
              <div className="d-flex align-items-center">
                <ZGButton className="btn btn-icon" hidden>
                  <FontAwesomeIcon
                    className="text-secondary mr-1"
                    icon={faRectangleList}
                  />{" "}
                  Edit Card Layout
                </ZGButton>
                <HideTableFieldsMenu />
              </div>
            </div>
          </div>
          <TableView layer={activeLayer} />
        </div>
      ) : (
        <div className={styles.zonegoatContainer}>No active layer.</div>
      )}
    </div>
  );
}

export default LayerDetails;
