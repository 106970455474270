import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./root-slice";
import projectReducer from "./project-slice";

export const store = configureStore({
  reducer: {
    root: rootReducer,
    project: projectReducer,
  },
});

export default store;
