/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { parseConfigs } from "@/helpers/helpers";
import { DATE_FIELD_FORMATES } from "@/constants";

function EditNumberColumnMenuOptions({ column, onUpdate }) {
  const [columnConfigs, setColumnConfigs] = useState(
    parseConfigs(column) || {},
  );
  const [numberFormat, setNumberFormat] = useState("");

  const confirmFormat = (value, key) => {
    const configs = JSON.stringify({ ...columnConfigs, [key]: value });
    onUpdate({ configs }, false, () => setNumberFormat(value));
  };

  useEffect(() => {
    const configs = parseConfigs(column) || {};
    setColumnConfigs(configs);
    if (configs.format !== undefined) {
      setNumberFormat(configs.format);
    } else {
      confirmFormat("complete_date", "format");
    }
  }, [column.configs]);

  return (
    <>
      <label
        className="dropdown-label"
        htmlFor={`date-field-format-${column.id}`}
      >
        Format
      </label>
      <select
        id={`number-field-format-${column.id}`}
        className="form-select"
        value={numberFormat}
        onChange={(event) => confirmFormat(event.target.value, "format")}
      >
        {Object.values(DATE_FIELD_FORMATES).map((format) => (
          <option
            value={format.value}
            key={`date-field-${column.id}-format-${format.value}`}
          >
            {format.label}
          </option>
        ))}
      </select>
    </>
  );
}

export default EditNumberColumnMenuOptions;
