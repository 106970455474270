import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { useDrag, useDrop } from "react-dnd";
import { setDraftColumnId } from "@/store/root-slice";
import EditColumnMenu from "./edit-column-menu/EditColumnMenu";

const COLUMN_TYPE = "COLUMN";

function TableHeader({ column, index, moveColumn }) {
  const dispatch = useDispatch();
  const activeColumnId = useSelector((state) =>
    state.root.activeCell ? state.root.activeCell.columnId : null,
  );
  const currentFieldType = useSelector((state) =>
    state.root.fieldTypes.find(
      (fieldType) => fieldType.id === column.field_type_id,
    ),
  );
  const activeLayer = useSelector(
    (state) => state.project.layers[state.project.activeLayerIndex],
  );

  const [, drag] = useDrag({
    type: COLUMN_TYPE,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: COLUMN_TYPE,
    drop: (draggedItem) => {
      moveColumn(draggedItem.index, index);
    },
  });

  const handleHeaderClick = () => {
    dispatch(setDraftColumnId(column.id));
  };

  return (
    <th
      className="cursor-pointer"
      style={{ minWidth: 250 }}
      onClick={handleHeaderClick}
      ref={(node) => drag(drop(node))}
    >
      <div className="px-2 py-1">
        <div className="d-flex align-content-center justify-content-between">
          <span>
            <span
              className={
                activeColumnId === column.id ? "text-zonegoat-primary" : ""
              }
            >
              {currentFieldType && (
                <FontAwesomeIcon
                  icon={icons[currentFieldType.icon]}
                  className="pr-2"
                />
              )}
              {column.name}
            </span>
            {column.required === true && (
              <span className="text-danger pl-1">*</span>
            )}
          </span>
          {activeLayer.text_marker_id === column.id && (
            <span>
              <FontAwesomeIcon
                className="p-0"
                icon={icons.faMessage}
                size="sm"
              />
            </span>
          )}
        </div>
        {currentFieldType && <EditColumnMenu column={column} />}
      </div>
    </th>
  );
}

export default TableHeader;
