import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { format } from "date-fns";
import axios from "@/services/client";
import { setCell } from "@/store/project-slice";
import { setActiveCell } from "@/store/root-slice";
import { isValidUrl, parseConfigs, formatNumber } from "@/helpers/helpers";
import { FIELD_TYPE_NAMES, DATE_FIELD_FORMATES } from "@/constants";
import CellInput from "./fields/CellInput";

function TableCell({ row, column }) {
  const tdRef = useRef();
  const [configs, setConfigs] = useState({});
  const isCellActive = useSelector(
    (state) =>
      state.root.activeCell &&
      state.root.activeCell.rowId === row.id &&
      state.root.activeCell.columnId === column.id,
  );
  const storeCell = useSelector((state) => {
    const cell =
      state.project.layers[state.project.activeLayerIndex].layer_points[row.id]
        ?.cells[column.id];
    return cell || { value: "" };
  });

  useEffect(() => {
    setConfigs(parseConfigs(column));
  }, [column.configs]);

  const dispatch = useDispatch();

  const fieldType = useSelector((state) =>
    state.root.fieldTypes.find(
      (cFieldType) => cFieldType.id === column.field_type_id,
    ),
  );

  const onEditingEnded = () => {
    dispatch(setActiveCell(null));
  };

  const updateCell = async (cellId, cellValue, skipFocusChange = false) => {
    try {
      const payload = { cell: { value: cellValue } };
      const updatedCell = await axios.patch(`/api/cells/${cellId}`, payload);
      if (!skipFocusChange) {
        onEditingEnded();
      }
      dispatch(setCell({ layerPointId: row.id, cell: updatedCell }));
    } catch (error) {
      toast.error("Error updating cell");
    }
  };

  const addNewCell = async (columnId, cellValue, skipFocusChange = false) => {
    try {
      const payload = {
        cell: {
          value: cellValue,
          field_id: columnId,
          layer_point_id: row.id,
        },
      };
      const newCell = await axios.post("/api/cells", payload);
      if (skipFocusChange) {
        onEditingEnded();
      }
      dispatch(setCell({ layerPointId: row.id, cell: newCell }));
    } catch (error) {
      toast.error("Error updating cell");
    }
  };

  const handleCellClick = () => {
    if (!column.is_derived) {
      const activeCell = {
        rowId: row.id,
        columnId: column.id,
      };
      dispatch(setActiveCell(activeCell));
    }
  };

  const formatValue = () => {
    if (fieldType?.name === FIELD_TYPE_NAMES.NUMBER) {
      return formatNumber(storeCell.value, configs.format);
    }
    if (
      fieldType?.name === FIELD_TYPE_NAMES.DATE &&
      configs.format === DATE_FIELD_FORMATES.COMPLETE_DATE.value &&
      storeCell.value
    ) {
      const formattedDate = format(new Date(storeCell.value), "MMMM, dd yyyy");
      return formattedDate;
    }
    return storeCell.value;
  };

  return (
    <td ref={tdRef} onClick={handleCellClick} className="visible-cell">
      {isCellActive || fieldType?.name === FIELD_TYPE_NAMES.CHECKBOX ? (
        <CellInput
          onEditingEnded={onEditingEnded}
          fieldType={fieldType}
          cell={storeCell}
          column={column}
          row={row}
          updateCell={updateCell}
          addNewCell={addNewCell}
        />
      ) : (
        <div
          className={`
          px-2 py-${storeCell.value ? 1 : 3} 
          ${column.is_derived ? "text-secondary" : ""}
          ${fieldType?.name === FIELD_TYPE_NAMES.NUMBER && "text-right pr-1"}
          `}
        >
          {fieldType?.name === FIELD_TYPE_NAMES.URL &&
          isValidUrl(storeCell.value) ? (
            <a
              className="celled-link"
              href={
                !storeCell.value.startsWith("http://") &&
                !storeCell.value.startsWith("https://")
                  ? `http://${storeCell.value}`
                  : storeCell.value
              }
              target="blank"
            >
              {storeCell.value}
            </a>
          ) : (
            formatValue()
          )}
        </div>
      )}
    </td>
  );
}

export default TableCell;
