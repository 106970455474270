import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icons from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { updateFieldApi } from "@/store/project-slice";
import { toast } from "react-toastify";
import Switch from "./Switch";

function FieldItem({ field, index }) {
  const dispatch = useDispatch();
  const currentFieldType = useSelector((state) =>
    state.root.fieldTypes.find(
      (fieldType) => fieldType.id === field.field_type_id,
    ),
  );

  const handleSwitchClick = async () => {
    try {
      await dispatch(
        updateFieldApi({ ...field, hidden_on_view: !field.hidden_on_view }),
      );
    } catch (error) {
      toast.error("Error updating column visibility");
    }
  };

  return (
    <div className="d-flex align-items-center" style={{ marginBottom: "17px" }}>
      <Switch
        className="h-21 cursor-pointer"
        checked={!field.hidden_on_view}
        onChange={handleSwitchClick}
      />
      <div
        className="pl-2 cursor-pointer"
        style={{
          color: "#606060",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
          opacity: field.hidden_on_view ? 0.5 : 1,
        }}
        role="button"
        tabIndex={1 + index}
        onClick={handleSwitchClick}
        onKeyDown={(e) => e.key === "Enter" && handleSwitchClick()}
      >
        {currentFieldType && (
          <FontAwesomeIcon
            icon={icons[currentFieldType.icon]}
            className="pr-2"
          />
        )}
        {field.name}
      </div>
    </div>
  );
}

function HideTableFieldsMenu() {
  const [showMenu, setShowMenu] = useState(false);
  const handleToggle = () => setShowMenu(!showMenu);
  const activeLayer = useSelector((state) =>
    state.project.activeLayerIndex !== null
      ? state.project.layers[state.project.activeLayerIndex]
      : null,
  );
  const orderedFields = [...activeLayer.fields].sort(
    (a, b) => a.col_number - b.col_number,
  );

  return (
    <div>
      <button
        type="button"
        className="btn btn-icon pr-0"
        onClick={() => setShowMenu(true)}
      >
        <FontAwesomeIcon
          className="text-secondary mr-1"
          icon={icons.faEyeSlash}
        />{" "}
        Hide Fields
      </button>
      <Dropdown
        show={showMenu}
        onToggle={handleToggle}
        className="zonegoat-dropdown"
      >
        <Dropdown.Menu
          className="hide-fields-dropdown-menu"
          style={{
            transform: "translateX(-170px)",
            width: 300,
            maxHeight: "35vh",
            overflowX: "scroll",
            padding: 10,
          }}
        >
          {orderedFields.map((field, index) => (
            <FieldItem
              key={`show-field-on-table-${field.id}`}
              field={field}
              index={index}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default HideTableFieldsMenu;
