import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { FIELD_TYPE_NAMES } from "@/constants";
import { setFilter } from "@/store/root-slice";
import SelectFieldFilter from "./SelectFieldFilter";
import NumberFieldFilter from "./NumberFieldFilter";

function FilterDropdown({ field }) {
  const currentFieldType = useSelector((state) =>
    state.root.fieldTypes.find(
      (fieldType) => fieldType.id === field.field_type_id,
    ),
  );

  const dispatch = useDispatch();
  const isSelectField = currentFieldType.name === FIELD_TYPE_NAMES.SELECT;
  const handleFilterChange = (filter) => {
    dispatch(setFilter(filter));
  };
  const isNumberField = currentFieldType.name === FIELD_TYPE_NAMES.NUMBER;
  const [showMenu, setShowMenu] = useState(false);

  const handleToggle = () => setShowMenu(!showMenu);

  let filterElement = <div />;

  if (isSelectField) {
    filterElement = (
      <SelectFieldFilter
        field={field}
        onChange={handleFilterChange}
        handleToggle={handleToggle}
      />
    );
  } else if (isNumberField) {
    filterElement = (
      <NumberFieldFilter
        field={field}
        onChange={handleFilterChange}
        handleToggle={handleToggle}
      />
    );
  }

  return (
    (isSelectField || isNumberField) && (
      <div className="map-layer-filter">
        <button
          type="button"
          className={`filter-btn ${showMenu ? "selected" : ""}`}
          onClick={() => setShowMenu(true)}
        >
          {field.name}
        </button>
        <Dropdown
          show={showMenu}
          onToggle={handleToggle}
          className="zonegoat-dropdown"
        >
          <Dropdown.Menu
            style={{
              width: 240,
              padding: 12,
              background: "#fff",
              marginTop: 3,
            }}
          >
            {filterElement}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  );
}

function MapLayerFiltersControl() {
  const activeLayer = useSelector(
    (state) => state.project.layers[state.project.activeLayerIndex],
  );

  return activeLayer.fields.map((field) => (
    <FilterDropdown key={`filter-dropdown-${field.id}`} field={field} />
  ));
}

export default MapLayerFiltersControl;
