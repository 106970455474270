import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "react-bootstrap/Nav";
import { setActiveLayerIndex } from "@/store/project-slice";
import NewLayerTab from "./NewLayerTab";
import LayerDetails from "./LayerDetails";
import LayerTab from "./LayerTab";
import styles from "../styles.module.css";

function ProjectLayersTabs() {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);

  useEffect(() => {
    if (project.layers.length && project.activeLayerIndex === null) {
      dispatch(setActiveLayerIndex(0));
    }
  }, [project.id]);

  const handleSelect = (eventKey) => {
    if (!Number.isNaN(Number(eventKey))) {
      dispatch(setActiveLayerIndex(eventKey));
    }
  };

  const handleLayerDeleted = (nextLayerIndex) => {
    dispatch(setActiveLayerIndex(nextLayerIndex));
  };

  return (
    <>
      <div className={styles.zonegoatContainer} style={{ marginTop: "5rem" }}>
        <Nav
          variant="tabs"
          activeKey={project.activeLayerIndex}
          onSelect={handleSelect}
          className="zonegoat-nav"
        >
          {project.layers &&
            project.layers.map((layer, index) => (
              <LayerTab
                key={`layer-tab-${layer.id}`}
                layer={layer}
                index={index}
                onDeleted={handleLayerDeleted}
              />
            ))}

          <NewLayerTab />
        </Nav>
      </div>
      <div className="my-2">
        {project.activeLayerIndex !== null && <LayerDetails />}
      </div>
    </>
  );
}

export default ProjectLayersTabs;
