import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Dropdown from "react-bootstrap/Dropdown";

function AddressInput({ value, onKeyDown, onBlur, onLocationSelected }) {
  const [address, setAddress] = useState(value);

  const handleChange = (a) => {
    setAddress(a);
  };

  const handleSelect = (a) => {
    setAddress(a);
    geocodeByAddress(a)
      .then((results) => getLatLng(results[0]))
      .then((latitudeLongitude) => {
        onLocationSelected({
          address: a,
          latitude: latitudeLongitude.lat,
          longitude: latitudeLongitude.lng,
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error("Error", error));
  };

  const handleKeyDown = (event, firstSuggestion) => {
    if (event.key === "Enter" && firstSuggestion) {
      handleSelect(firstSuggestion);
    }
    onKeyDown(event);
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <>
          <div className="input-group h-100">
            <input
              {...getInputProps({
                className: `location-search-input celled-input`,
              })}
              onBlur={onBlur}
              onKeyDown={(e) => handleKeyDown(e, suggestions[0]?.description)}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </div>
          <Dropdown show={suggestions.length}>
            <Dropdown.Menu>
              {suggestions.map((suggestion) => {
                return (
                  <Dropdown.Item
                    {...getSuggestionItemProps(suggestion, {})}
                    key={`address-suggestion-${suggestion.placeId}`}
                  >
                    {suggestion.description}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}
    </PlacesAutocomplete>
  );
}

export default AddressInput;
