import React from "react";
import ZoneGoatTable from "@/components/zone-goat-table/ZoneGoatTable";

function TableView({ layer }) {
  const orderedColumns = [...layer.fields].sort(
    (a, b) => a.col_number - b.col_number,
  );
  const visibleColumns = orderedColumns.filter(
    (orderedColumn) => !orderedColumn.hidden_on_view,
  );
  return (
    layer.fields &&
    layer.fields.length > 0 && (
      <ZoneGoatTable
        layerId={layer.id}
        columns={visibleColumns}
        data={layer.layer_points}
      />
    )
  );
}

export default TableView;
