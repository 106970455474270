const globalConfig = {
  // // Production
  zonegoatRegURL: "https://reg.zonegoat.com/api",
  zonegoatGovURL: "https://gov.zonegoat.com/api",
  zonegoatCoreURL: "https://dashboard.zonegoat.com/api",
  upgradeImageURL: "https://dashboard.zonegoat.com/villa.svg",
  zonegoatPropertyUrl: "https://dashboard.zonegoat.com/zonegoat-property.png",
  zonegoatVillageIconUrl: "https://dashboard.zonegoat.com/village.png",
  builderImageUrl: "https://dashboard.zonegoat.com/builderImage.png",

  // Staging
  // zonegoatGovURL: 'https://zg-gov-staging.herokuapp.com/api',
  // zonegoatCoreURL: 'https://staging-zg-core.herokuapp.com/api',
  // upgradeImageURL: 'https://staging-zg-core.herokuapp.com/villa.svg',
  // zonegoatPropertyUrl: 'https://staging-zg-core.herokuapp.com/zonegoat-property.png',
  // zonegoatVillageIconUrl: 'http://localhost:3000/village.png',
  // builderImageUrl: 'https://staging-zg-core.herokuapp.com/builderImage.png'

  // // Development
  //  zonegoatCoreURL: 'http://dd08-24-212-232-176.ngrok.io/api',
  //   zonegoatCoreURL: 'http://localhost:3000/api',
  //   zonegoatRegURL: 'http://localhost:4000/api',
  //   zonegoatGovURL: 'http://localhost:5000/api',
  //   upgradeImageURL: 'http://localhost:3000/villa.svg',
  //   zonegoatPropertyUrl: 'http://localhost:3000/zonegoat-property.png',
  //   zonegoatVillageIconUrl: 'http://localhost:3000/village.png',
  //   builderImageUrl: 'http://localhost:3000/builderImage.png'
};

export default globalConfig;
