import axios from "axios";
import { toast } from "react-toastify";

const client = axios.create();

client.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (
      error.response?.data?.error &&
      typeof error.response.data.error === "string"
    ) {
      toast.error(error.response.data.error);
    }
    return Promise.reject(error);
  },
);

export default client;
