import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownDivider from "react-bootstrap/DropdownDivider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDeleteLeft,
  faEyeSlash,
  faFileCsv,
  faFileImport,
  faPaste,
  faRemove,
  faChevronRight,
  faMessage,
  faFaceSmile,
  faIcons,
} from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import { toast } from "react-toastify";
import axios from "@/services/client";
import { findNextIndex } from "@/helpers/helpers";
import EmojiPicker from "emoji-picker-react";
import {
  updateLayer,
  deleteLayer,
  setLayerWithoutConversion,
} from "@/store/project-slice";
import { setActiveEditMenuLayerId } from "@/store/root-slice";
import { customEmojis } from "@/constants";
import TextInput from "../../TextInput";
import LayerIcon from "./LayerIcon";

const EditLayerMenu = React.forwardRef(
  (
    {
      children,
      style,
      className,
      "aria-labelledby": labeledBy,
      confirmLayerName,
      onValidStateChange,
      layerName,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {children[0]}
        {children[1]}
        <div>
          <TextInput
            label="Layer name"
            placeholder="Enter name of the layer"
            onConfirm={confirmLayerName}
            onValidStateChange={onValidStateChange}
            value={layerName}
            sm={false}
          />
        </div>
        {children.slice(2)}
      </div>
    );
  },
);

const ImportDataSubDropdownToggle = React.forwardRef(
  ({ children, onClick }, ref) => (
    // eslint-disable-next-line
    <p
      className="m-0 d-flex justify-content-between align-items-center w-100"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </p>
  ),
);

function LayerTab({ layer, index, onDeleted }) {
  const totalLayers = useSelector((state) => {
    return state.project.layers.length;
  });
  const showMenu = useSelector(
    (state) => state.root.activeEditMenuLayerId === layer.id,
  );
  const AddressFiedType = useSelector((state) =>
    state.root.fieldTypes.find((ft) => ft.name === "Address"),
  );
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showImportDataSubMenu, setShowImportDataSubMenu] = useState(false);
  const [autoClose, setAutoClose] = useState("outside");
  const dispatch = useDispatch();
  const emojiPickerDropdownRef = useRef(null);
  const editLayerDropdownRef = useRef(null);

  const handleContextMenu = (e) => {
    e.preventDefault();
    dispatch(setActiveEditMenuLayerId(layer.id));
  };

  const handleToggle = () => {
    if (showMenu) {
      dispatch(setActiveEditMenuLayerId(null));
    } else {
      dispatch(setActiveEditMenuLayerId(layer.id));
    }
  };

  const handleMainDropdownSelect = (eventKey) => {
    if (eventKey !== "ImportData" && eventKey !== "EditLayerNameInput") {
      handleToggle();
    }
  };

  const handleImportDataDropdownSelect = () => {
    handleToggle();
  };

  const handleClickOutside = (event) => {
    if (
      emojiPickerDropdownRef.current &&
      editLayerDropdownRef.current &&
      !emojiPickerDropdownRef.current.contains(event.target) &&
      !editLayerDropdownRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  const confirmLayerName = async (newLayerName) => {
    if (newLayerName !== layer.name) {
      dispatch(updateLayer({ id: layer.id, name: newLayerName }));
    }
  };

  const onValidStateChange = (isValid) => {
    if (!isValid) {
      setAutoClose(false);
    } else {
      setAutoClose("outside");
    }
  };

  const onDeleteLayer = async () => {
    if (totalLayers === 1) {
      return;
    }
    const nextIndex = findNextIndex({ length: totalLayers }, index);
    try {
      await axios.delete(`/api/layers/${layer.id}`);
      dispatch(deleteLayer(layer.id));
      onDeleted(nextIndex - 1);
    } catch (error) {
      toast.error(`Error deleting layer: ${error.message}`);
    }
  };

  const handleChangeIconClick = () => {
    setShowEmojiPicker(true);
  };

  const handlePickedEmoji = async (emojiData) => {
    setShowEmojiPicker(false);
    try {
      const response = await axios.patch(`/api/layers/${layer.id}`, {
        layer: { icon: emojiData.emoji },
      });
      dispatch(
        setLayerWithoutConversion({
          layerId: layer.id,
          updatedData: response,
        }),
      );
    } catch (error) {
      toast.error(`Error updating layer icon: ${error.message}`);
    }
  };

  const toggleTextMarkerClick = () => {
    const addressField = layer.fields.find(
      (f) => f.field_type_id === AddressFiedType.id,
    );
    const textMarkerField = layer.text_marker_id ? null : addressField.id;
    dispatch(
      updateLayer({
        id: layer.id,
        text_marker_id: textMarkerField,
      }),
    );
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener("click", handleClickOutside);

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Dropdown
        show={showMenu}
        onToggle={handleToggle}
        onSelect={handleMainDropdownSelect}
        autoClose={autoClose}
        ref={editLayerDropdownRef}
        className="zonegoat-dropdown"
      >
        <Dropdown.Menu
          as={EditLayerMenu}
          layerName={layer.name}
          confirmLayerName={confirmLayerName}
          onValidStateChange={onValidStateChange}
          style={{ padding: 7, width: 270 }}
        >
          <Dropdown.Item
            as={Dropdown}
            drop="end"
            eventKey="ImportData"
            className="d-flex align-items-center p-0 cursor-pointer"
            onSelect={handleImportDataDropdownSelect}
            onMouseEnter={() => setShowImportDataSubMenu(true)}
            onMouseLeave={() => setShowImportDataSubMenu(false)}
          >
            <Dropdown.Toggle as={ImportDataSubDropdownToggle}>
              <span className="m-0 d-flex justify-content-between align-items-center">
                <span className="dropdown-icon-container">
                  <FontAwesomeIcon className="p-0" icon={faFileImport} />
                </span>{" "}
                <span className="pl-2">Import Data</span>
              </span>
              <FontAwesomeIcon icon={faChevronRight} className="p-0" />
            </Dropdown.Toggle>

            <Dropdown.Menu show={showImportDataSubMenu}>
              <Dropdown.Item className="has-icon" disabled>
                <FontAwesomeIcon icon={faPaste} />
                Paste Data
              </Dropdown.Item>
              <Dropdown.Item className="has-icon" disabled>
                <FontAwesomeIcon icon={faFileCsv} />
                CSV file
              </Dropdown.Item>
              <Dropdown.Item className="has-icon" disabled>
                <FontAwesomeIcon icon={faFileExcel} />
                Microsoft Excel
              </Dropdown.Item>
              <Dropdown.Item className="has-icon" disabled>
                <FontAwesomeIcon icon={faGoogleDrive} />
                Google Sheets
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown.Item>

          <DropdownDivider />

          <DropdownDivider />

          <Dropdown.Item className="d-flex align-items-center p-0 cursor-pointer">
            <span className="dropdown-icon-container">
              <FontAwesomeIcon className="p-0" icon={faEyeSlash} />
            </span>
            <span className="pl-2">Hide Layer on map</span>
          </Dropdown.Item>

          <DropdownDivider />

          <Dropdown.Item
            className="d-flex align-items-center p-0 cursor-pointer"
            onClick={toggleTextMarkerClick}
          >
            <span className="dropdown-icon-container">
              <FontAwesomeIcon
                className="p-0"
                icon={layer.text_marker_id ? faFaceSmile : faMessage}
              />
            </span>
            <span className="pl-2">
              {layer.text_marker_id ? "Use Emoji Marker" : "Use Text Marker"}
            </span>
          </Dropdown.Item>

          {!layer.text_marker_id && (
            <Dropdown.Item
              className="d-flex align-items-center p-0 cursor-pointer"
              onClick={handleChangeIconClick}
            >
              <span className="dropdown-icon-container">
                <FontAwesomeIcon className="p-0" icon={faIcons} />
              </span>
              <span className="pl-2">Change Icon</span>
            </Dropdown.Item>
          )}

          <DropdownDivider />

          <Dropdown.Item className="d-flex align-items-center p-0 cursor-pointer">
            <span className="dropdown-icon-container">
              <FontAwesomeIcon className="p-0" icon={faRemove} size="lg" />
            </span>
            <span className="pl-2">Clear Data</span>
          </Dropdown.Item>

          <Dropdown.Item
            className="d-flex align-items-center p-0 cursor-pointer"
            disabled={totalLayers === 1}
            onClick={onDeleteLayer}
          >
            <span className="dropdown-icon-container">
              <FontAwesomeIcon className="p-0" icon={faDeleteLeft} />
            </span>
            <span className="pl-2">Delete Layer</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown
        ref={emojiPickerDropdownRef}
        show={showEmojiPicker}
        className="zonegoat-dropdown"
        autoClose
      >
        <Dropdown.Menu className="p-0 border-0 background-transparent">
          <Dropdown.Item className="p-0">
            <EmojiPicker
              onEmojiClick={handlePickedEmoji}
              customEmojis={customEmojis}
              previewConfig={{
                defaultCaption: "Select an icon.",
              }}
              skinTonesDisabled
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Nav.Item className="layer-tab" onContextMenu={handleContextMenu}>
        <Nav.Link eventKey={index} className="d-flex align-items-center">
          <LayerIcon icon={layer.icon} />
          <span className="pl-2">{layer.name}</span>
        </Nav.Link>
      </Nav.Item>
    </>
  );
}

export default LayerTab;
