import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownDivider from "react-bootstrap/DropdownDivider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import axios from "@/services/client";
import { setDraftColumnId } from "@/store/root-slice";
import TextInput from "@/components/TextInput";
import {
  deleteFieldOfCurrentLayer,
  updateFieldApi,
  updateLayer,
} from "@/store/project-slice";
import { FIELD_TYPE_NAMES } from "@/constants";
import EditSelectColumnMenuOptions from "./EditSelectColumnMenuOptions";
import EditCheckboxColumnMenuOptions from "./EditCheckboxColumnMenuOptions";
import EditNumberColumnMenuOptions from "./EditNumberColumnMenuOptions";
import EditDateColumnMenuOptions from "./EditDateColumnMenuOptions";

const EditColumnMenuElement = React.forwardRef(
  (
    {
      children,
      style,
      className,
      column,
      onUpdate,
      currentFieldType,
      "aria-labelledby": labeledBy,
      confirmColumnName,
      onValidStateChange,
    },
    ref,
  ) => {
    const childrenSplitIndex = 4;

    let additionalMenuOptions = "";
    if (currentFieldType.name === FIELD_TYPE_NAMES.SELECT) {
      additionalMenuOptions = (
        <>
          <EditSelectColumnMenuOptions column={column} onUpdate={onUpdate} />
          <DropdownDivider />
        </>
      );
    } else if (currentFieldType.name === FIELD_TYPE_NAMES.CHECKBOX) {
      additionalMenuOptions = (
        <>
          <EditCheckboxColumnMenuOptions column={column} onUpdate={onUpdate} />
          <DropdownDivider />
        </>
      );
    } else if (currentFieldType.name === FIELD_TYPE_NAMES.NUMBER) {
      additionalMenuOptions = (
        <>
          <EditNumberColumnMenuOptions column={column} onUpdate={onUpdate} />
          <DropdownDivider />
        </>
      );
    } else if (currentFieldType.name === FIELD_TYPE_NAMES.DATE) {
      additionalMenuOptions = (
        <>
          <EditDateColumnMenuOptions column={column} onUpdate={onUpdate} />
          <DropdownDivider />
        </>
      );
    }

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className="d-flex align-items-center">
          <span
            className="dropdown-icon-container filled"
            style={{ width: 42 }}
          >
            <FontAwesomeIcon
              icon={icons[currentFieldType.icon]}
              className="text-secondary"
            />
          </span>
          <TextInput
            label="Column Name"
            onConfirm={confirmColumnName}
            onValidStateChange={onValidStateChange}
            value={column.name}
            className="form-control ml-2"
          />
        </div>
        {children.slice(0, childrenSplitIndex)}
        {additionalMenuOptions}
        {children.slice(childrenSplitIndex)}
      </div>
    );
  },
);

function EditColumnMenu({ column }) {
  const [autoClose, setAutoClose] = useState("outside");
  const currentFieldType = useSelector((state) =>
    state.root.fieldTypes.find(
      (fieldType) => fieldType.id === column.field_type_id,
    ),
  );
  const currentlyBeingEdited = useSelector(
    (state) => state.root.draftColumnId === column.id,
  );
  const activeLayer = useSelector(
    (state) => state.project.layers[state.project.activeLayerIndex],
  );
  const dispatch = useDispatch();

  const handleToggle = () => {
    if (currentlyBeingEdited) {
      dispatch(setDraftColumnId(null));
    } else {
      dispatch(setDraftColumnId(column.id));
    }
  };

  const updateField = async (
    field,
    closeDrawer = true,
    callback = () => {},
  ) => {
    try {
      await dispatch(updateFieldApi({ id: column.id, ...field }));
      if (closeDrawer) {
        dispatch(setDraftColumnId(null));
      }
      callback();
    } catch (error) {
      toast.error("Error updating column");
    }
  };

  const deleteField = async () => {
    try {
      await axios.delete(`/api/fields/${column.id}`);
      dispatch(deleteFieldOfCurrentLayer({ fieldId: column.id }));
    } catch (error) {
      toast.error("Error deleting column");
    }
  };

  // const clearField = async () => {
  //   try {
  //     await axios.post(`/api/fields/${column.id}/clear`);
  //     dispatch(clearFieldOfCurrentLayer({ fieldId: column.id }));
  //   } catch (error) {
  //     toast.error("Error deleting column");
  //   }
  // };

  const confirmColumnName = async (newColumnName) => {
    if (column.name !== newColumnName) {
      updateField({ name: newColumnName });
    }
  };

  const onValidStateChange = (isValid) => {
    if (!isValid) {
      setAutoClose(false);
    } else {
      setAutoClose("outside");
    }
  };

  const onDeleteField = () => {
    if (!column.required) {
      deleteField();
    }
  };

  const onClearField = () => {
    // clearField();
  };

  const onHideMap = () => {
    updateField({ hidden_on_map: !column.hidden_on_map });
  };

  const onSetAsMapMarker = () => {
    if (activeLayer.text_marker_id !== column.id) {
      dispatch(
        updateLayer({ id: activeLayer.id, text_marker_id: column.id }),
      ).then(handleToggle);
    }
  };

  return (
    <Dropdown
      show={currentlyBeingEdited}
      onToggle={handleToggle}
      autoClose={autoClose}
      className="zonegoat-dropdown"
    >
      <Dropdown.Menu
        as={EditColumnMenuElement}
        column={column}
        onUpdate={updateField}
        currentFieldType={currentFieldType}
        confirmColumnName={confirmColumnName}
        onValidStateChange={onValidStateChange}
        style={{ padding: "0.5rem 0.875rem" }}
      >
        <DropdownDivider />

        <Dropdown.Item
          className="d-flex align-items-center p-0 cursor-pointer"
          onClick={onHideMap}
        >
          <span className="dropdown-icon-container">
            <FontAwesomeIcon
              className="p-0"
              icon={column.hidden_on_map ? icons.faEye : icons.faEyeSlash}
            />
          </span>
          <span className="pl-2">
            {column.hidden_on_map ? "Display" : "Hide"} on Map
          </span>
        </Dropdown.Item>

        {activeLayer.text_marker_id && (
          <Dropdown.Item
            className="d-flex align-items-center p-0 cursor-pointer"
            disabled={activeLayer.text_marker_id === column.id}
            onClick={onSetAsMapMarker}
          >
            <span className="dropdown-icon-container">
              <FontAwesomeIcon className="p-0" icon={icons.faMessage} />
            </span>
            <span className="pl-2">Set as Map Marker</span>
          </Dropdown.Item>
        )}
        <DropdownDivider />

        <Dropdown.Item
          className="d-flex align-items-center p-0 cursor-pointer"
          onClick={onClearField}
        >
          <span className="dropdown-icon-container">
            <FontAwesomeIcon className="p-0" icon={icons.faRemove} size="lg" />
          </span>
          <span className="pl-2">Clear Data</span>
        </Dropdown.Item>

        <Dropdown.Item
          className="d-flex align-items-center p-0 cursor-pointer"
          onClick={onDeleteField}
          disabled={column.required}
        >
          <span className="dropdown-icon-container">
            <FontAwesomeIcon className="p-0" icon={icons.faDeleteLeft} />
          </span>
          <span className="pl-2">Delete Field</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default EditColumnMenu;
