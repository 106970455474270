import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassLocation } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import TextInput from "@/components/TextInput";
import { toast } from "react-toastify";
import axios from "@/services/client";
import { addZoomLink } from "@/store/project-slice";
import { useSelector, useDispatch } from "react-redux";
import MapQuickZoomLink from "./MapQuickZoomLink";

function MapQuickZoomControl({ map }) {
  const [showMenu, setShowMenu] = useState(false);
  const [quickZoomName, setQuickZoomName] = useState("");
  const quickZoomLinks = useSelector((state) => state.project?.zoom_links);
  const projectId = useSelector((state) => state.project.id);
  const maxZoomLinkCount = useSelector(
    (state) => state.project.max_zoom_link_count,
  );
  const dispatch = useDispatch();

  const handleToggle = () => setShowMenu(!showMenu);

  const addNewZoomLink = async (zoomLink) => {
    try {
      const payload = {
        zoom_link: zoomLink,
      };
      const newZoomLink = await axios.post("/api/zoom_links", payload);
      setQuickZoomName("");
      dispatch(addZoomLink({ zoomLink: newZoomLink }));
    } catch (error) {
      toast.error("Error updating cell");
    }
  };

  const onQuickZoomNameConfirm = (value) => {
    const mapCenter = map.getCenter();
    const newZoomLink = {
      name: value,
      zoom_level: map.getZoom(),
      latitude: mapCenter.lat(),
      longitude: mapCenter.lng(),
      project_id: projectId,
    };
    addNewZoomLink(newZoomLink);
    setQuickZoomName("");
  };

  return (
    <>
      <button
        type="button"
        className="map-control-btn"
        onClick={() => setShowMenu(true)}
      >
        <FontAwesomeIcon icon={faMagnifyingGlassLocation} size="lg" />
      </button>
      <Dropdown
        show={showMenu}
        onToggle={handleToggle}
        className="zonegoat-dropdown"
      >
        <Dropdown.Menu className="map-quick-zoom-control-dropdown-menu">
          <h6 className="text-zonegoat-gray-1 fw-bolder">Quick Zoom</h6>
          <p className="text-zonegoat-gray mb-2 fw-sm">
            Save and instantly return to a specific viewpoint and zoom level on
            the map.
          </p>
          <hr className="mt-1 mb-3" />
          {quickZoomLinks.length < maxZoomLinkCount && (
            <TextInput
              onConfirm={onQuickZoomNameConfirm}
              value={quickZoomName}
              sm={false}
              placeholder="Type a name"
              submitOnBlur={false}
              onChange={(value) => setQuickZoomName(value)}
              clearErrorsOnBlur
            />
          )}
          <div className="mt-3">
            {quickZoomLinks.map((zoomLink) => (
              <MapQuickZoomLink
                zoomLink={zoomLink}
                key={`map-zoom-link-${zoomLink.id}`}
              />
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default MapQuickZoomControl;
