import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { object, string } from "yup";
import { toast } from "react-toastify";
import { yupValidate } from "@/helpers/helpers";
import { URL_REGEX } from "@/constants";

function TextInput({
  label,
  onConfirm,
  autoFocus = true,
  placeholder = "",
  onCancel = () => {},
  onChange = () => {},
  onValidStateChange = () => {},
  value = "",
  sm = true,
  required = true,
  className = "",
  submitOnBlur = true,
  clearErrorsOnBlur = false,
  type = "Text",
  showError = true,
}) {
  const [newValue, setNewValue] = useState(value);
  const [hasErrors, setHasErrors] = useState(false);
  const isEditing = !!value;
  const inputRef = useRef(null);

  let valueSchema = string();
  if (required) {
    valueSchema = valueSchema.required(`${label || "Value"} must be provided.`);
  }
  if (type === "URL") {
    valueSchema = valueSchema.matches(URL_REGEX, "Value must be a valid URL");
  }
  const fieldSchema = object({
    value: valueSchema.label(label),
  });

  useLayoutEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 1);
    }
  }, [autoFocus]);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const handleSubmit = () => {
    const { errors, valid } = yupValidate(fieldSchema, { value: newValue });

    if (valid) {
      onConfirm(newValue);
    } else if (errors?.length) {
      if (showError) {
        toast.error(errors[0]);
      } else {
        onConfirm(newValue);
      }
    } else {
      onCancel();
    }
  };

  const onBlur = (e) => {
    e.preventDefault();
    if (submitOnBlur) {
      handleSubmit();
    }
    if (clearErrorsOnBlur) {
      setHasErrors(false);
    }
  };

  const handleInputChange = (e) => {
    if (isEditing) {
      const { valid } = yupValidate(fieldSchema, { value: e.target.value });
      onValidStateChange(valid);
      setHasErrors(!valid);
    }
    setNewValue(e.target.value);
    onChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <input
      type="text"
      className={`text-input ${className || `form-control mt-1 ${sm ? "form-control-sm" : ""}`} ${hasErrors ? "is-invalid" : ""}`}
      ref={inputRef}
      value={newValue}
      onChange={handleInputChange}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
    />
  );
}

export default TextInput;
