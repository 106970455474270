import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGripVertical,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";
import ZGButton from "@/components/ZGButton";
import { toast } from "react-toastify";
import axios from "@/services/client";
import { removeZoomLink } from "@/store/project-slice";
import { useDispatch } from "react-redux";

function MapQuickZoomLink({ zoomLink }) {
  const [deleting, setDeleting] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const deleteZoomLink = async () => {
    try {
      await axios.delete(`/api/zoom_links/${zoomLink.id}`);
      setDeleting(false);
      dispatch(removeZoomLink({ zoomLink }));
    } catch (error) {
      toast.error("Error updating cell");
    }
  };

  const onDelete = () => {
    setDeleting(true);
    deleteZoomLink();
  };

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="d-flex align-items-center">
        <FontAwesomeIcon
          icon={faGripVertical}
          className="text-zonegoat-gray-2"
        />
        <span className="badge bg-zonegoat-green text-white ml-2">
          {zoomLink.name}
        </span>
      </span>
      <span style={{ height: 26 }}>
        {isHovered && (
          <ZGButton
            className="btn btn-icon p-0"
            loading={deleting}
            onClick={onDelete}
            icon
          >
            <FontAwesomeIcon
              icon={faDeleteLeft}
              className="text-zonegaot-red"
            />
          </ZGButton>
        )}
      </span>
    </div>
  );
}

export default MapQuickZoomLink;
