import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { object, string } from "yup";
import axios from "@/services/client";
import { toast } from "react-toastify";
import { addProject } from "@/store/root-slice";
import ZGButton from "../ZGButton";

const projectSchema = object({
  name: string().required().label("Project Name"),
});

function ProjectForm() {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(projectSchema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post("/api/projects", data);
      reset();
      dispatch(addProject({ project: response }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error creating project:", error);
      toast.error("Error creating project", error);
    }
  };

  return (
    <form
      className="row needs-validation project-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className="col-auto">
        <input
          type="text"
          className={`form-control form-control-lg fw-semibold ${formState.errors.name && "is-invalid"}`}
          placeholder=""
          {...register("name")}
          disabled={formState.isSubmitting}
        />
        {formState.errors.name && (
          <div className="invalid-feedback">
            {formState.errors.name.message}
          </div>
        )}
      </div>
      <div className="col-auto">
        <ZGButton
          type="submit"
          className="btn btn-primary px-4 py-2-5 fw-semibold"
          loading={formState.isSubmitting}
        >
          CREATE A NEW PROJECT
        </ZGButton>
      </div>
    </form>
  );
}
export default ProjectForm;
