import React from "react";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from "@/services/client";
import { addLayerPoint } from "@/store/project-slice";
import ZGButton from "@/components/ZGButton";

function NewTableRow({ layerId, totalColumns, totalRows }) {
  const dispatch = useDispatch();

  const handleAddNewRow = async () => {
    try {
      const payload = {
        layer_point: {
          row_number: totalRows + 1,
          layer_id: layerId,
        },
      };
      const newRow = await axios.post("/api/layer_points", payload);

      dispatch(addLayerPoint({ layerId, newLayerPoint: newRow }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error adding new row:", error);
    }
  };

  return (
    <tr>
      <td className="row-actions-td" />
      <td
        colSpan={totalColumns + 2}
        className="px-2 cursor-pointer"
        style={{ maxHeight: 32, minHeight: 32 }}
        onClick={handleAddNewRow}
      >
        <span style={{ position: "sticky", left: "-4rem" }}>
          <ZGButton className="btn btn-text btn-sm">
            <FontAwesomeIcon icon={faPlus} /> Add Row
          </ZGButton>
        </span>
      </td>
    </tr>
  );
}

export default NewTableRow;
