import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faDeleteLeft,
  faGripVertical,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import ZGButton from "@/components/ZGButton";
import axios from "@/services/client";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchLayer } from "@/store/project-slice";

function TableRowActionMenu({ row, rowActionStyle }) {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);

  const handleToggle = () => setShowMenu(!showMenu);

  const handleDeleteRow = async () => {
    try {
      await axios.delete(`/api/layer_points/${row.id}`);
      dispatch(fetchLayer(row.layer_id));
    } catch (error) {
      toast.error("Error deleting row:", error.message);
    }
  };

  const handleClearRow = async () => {
    try {
      await axios.post(`/api/layer_points/${row.id}/clear`);
      dispatch(fetchLayer(row.layer_id));
      setShowMenu(false);
    } catch (error) {
      toast.error("Error Clearing row:", error.message);
    }
  };

  return (
    <span>
      <Dropdown
        show={showMenu}
        onToggle={handleToggle}
        autoClose="outside"
        className="zonegoat-dropdown d-inline"
      >
        <Dropdown.Menu>
          <Dropdown.Item className="has-icon" onClick={handleClearRow}>
            <FontAwesomeIcon icon={faClose} size="xl" />
            Clear Row Data
          </Dropdown.Item>

          <Dropdown.Item className="has-icon" onClick={handleDeleteRow}>
            <FontAwesomeIcon icon={faDeleteLeft} />
            Delete Row
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <ZGButton
        className="btn btn-icon btn-sm"
        onClick={() => setShowMenu(true)}
      >
        <FontAwesomeIcon
          style={rowActionStyle}
          icon={faGripVertical}
          className="text-secondary"
        />
      </ZGButton>
    </span>
  );
}

export default TableRowActionMenu;
