import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCircleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { FIELD_TYPE_NAMES } from "@/constants";
import styles from "../styles.module.css";
import LayerIcon from "../layers/LayerIcon";

function MapSearchField({ onSearch, onClearSearch }) {
  const addresses = useSelector((state) => {
    // eslint-disable-next-line prefer-destructuring
    const fieldTypes = state.root.fieldTypes;
    const addressFieldType = fieldTypes.find(
      (fieldType) => fieldType.name === FIELD_TYPE_NAMES.ADDRESS,
    );
    const coordinatesFieldType = fieldTypes.find(
      (fieldType) => fieldType.name === FIELD_TYPE_NAMES.COORDINATES,
    );
    const addressesValues = [];
    state.project.layers.forEach((layer, layerIndex) => {
      const addressField = layer.fields.find(
        (field) => field.field_type_id === addressFieldType?.id,
      );
      const coordinatesField = layer.fields.find(
        (field) => field.field_type_id === coordinatesFieldType?.id,
      );
      Object.values(layer.layer_points).forEach((layerPoint) => {
        const addressCell = layerPoint.cells[addressField?.id];
        const coordinatesCell = layerPoint.cells[coordinatesField?.id];
        if (addressCell) {
          addressesValues.push({
            value: addressCell.value,
            layerPointId: addressCell.layer_point_id,
            layerIndex,
            layerIcon: layer.icon,
            coordinates: coordinatesCell?.value,
          });
        }
      });
    });
    return addressesValues;
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [showSearchButton, setShowSearchButton] = useState(true);

  const handleSearch = (event) => {
    setShowResults(true);
    setShowSearchButton(true);
    const query = event.target.value;
    setSearchQuery(query);
    const matchedAddresses = [];
    addresses.forEach((addr) => {
      if (addr.value.toLowerCase().includes(query.toLowerCase())) {
        matchedAddresses.push(addr);
      }
    });
    setResults(matchedAddresses);
  };

  const handleSearchClick = () => {
    if (results.length) {
      onSearch(results[0]);
      setShowResults(false);
      setSearchQuery(results[0].value);
      setShowSearchButton(false);
    }
  };

  const handleClearClick = () => {
    setSearchQuery("");
    setResults([]);
    setShowSearchButton(true);
    onClearSearch();
  };

  const handleSearchItemClick = (result) => {
    onSearch(result);
    setShowResults(false);
    setSearchQuery(result.value);
    setShowSearchButton(false);
  };

  return (
    <div className="d-flex flex-column">
      <div
        className={`input-group ${styles.mapSearchFieldGroup}`}
        style={{ flexWrap: "nowrap" }}
      >
        <input
          type="text"
          style={{ fontSize: 14, color: "#404244", height: 40 }}
          className="form-control text-truncate fw-semibold"
          placeholder="Search this map"
          value={searchQuery}
          onChange={handleSearch}
          onFocus={() => setShowResults(true)}
          onBlur={() =>
            setTimeout(() => {
              setShowResults(false);
            }, 100)
          }
        />
        <div className={styles.buttonContainer}>
          {showSearchButton ? (
            <button
              className={`btn btn-icon ${styles.searchButton}`}
              type="button"
              onClick={handleSearchClick}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          ) : (
            <button
              className="btn btn-icon"
              type="button"
              onClick={handleClearClick}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          )}
        </div>
      </div>
      <Dropdown show={showResults && searchQuery.length > 0} style={{ top: 0 }}>
        <Dropdown.Menu
          style={{
            minWidth: 330,
            maxWidth: 330,
            padding: results.length > 0 ? "20px 10px" : 10,
          }}
        >
          {results.map((result) => (
            <Dropdown.Item
              key={`map-search-result-${result.layerPointId}`}
              className="d-flex align-items-center"
              style={{ height: 40 }}
              onClick={() => handleSearchItemClick(result)}
            >
              <LayerIcon
                icon={result.layerIcon}
                style={{ width: 20, marginLeft: -9, marginTop: -10 }}
              />
              <div className="ml-2 text-truncate">{result.value}</div>
            </Dropdown.Item>
          ))}
          {results.length === 0 && (
            <Dropdown.Item className="d-flex align-items-center" disabled>
              <FontAwesomeIcon icon={faCircleExclamation} />
              <div className="ml-2">No Match Found.</div>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default MapSearchField;
