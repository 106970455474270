/* global localStorage window */
import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Settings from "./Settings";
import SideNavbar from "./SideNavbar";
import StaticTerms from "./StaticTerms";
import StaticPrivacy from "./StaticPrivacy";
import StaticDislaimer from "./StaticDislaimer";
import globalConfig from "./global";
import Projects from "./projects/index";
import ProjectMapView from "./projects/ProjectMapView";
import ProjectView from "./projects/ProjectView";
import { store } from "../store/store";
import "./styles.css";

class ReactHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountUuid: "",
      userUuid: "",
    };
  }

  componentDidMount() {
    const accountUuid = localStorage.getItem("zonegoat_account_uuid");
    const userUuid = localStorage.getItem("zonegoat_user_uuid");
    this.setState({
      accountUuid,
      userUuid,
    });

    this.loadUserAccount(accountUuid, userUuid);
  }

  loadUserAccount(accountUuid, userUuid) {
    const apiBaseUrl = globalConfig.zonegoatCoreURL;
    const requestPath = `${apiBaseUrl}/accounts/${accountUuid}/users/${userUuid}`;

    fetch(requestPath)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            email: result.email,
          });
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log("userAccount: errors ", error);
        },
      );
  }

  // reloadUserAccount() {
  //   this.loadUserAccount(this.state.accountUuid, this.state.userUuid);
  // }

  updateAccountUuid(newAccountUuid) {
    localStorage.setItem("zonegoat_account_uuid", newAccountUuid);
    this.setState({ accountUuid: newAccountUuid }, window.location.assign("/"));
  }

  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <div className="d-flex bg-light">
            <div
              className="d-flex flex-column align-items-center justify-content-between sticky-top"
              style={{
                backgroundColor: "black",
                overflowY: "scroll",
                height: "100vh",
                width: "var(--left-nav-bar)",
              }}
            >
              {/* eslint-disable-next-line */}
              <SideNavbar accountUuid={this.state.accountUuid} userUuid={this.state.userUuid}
                updateAccountUuid={this.updateAccountUuid}
              />
            </div>
            <div style={{ width: "calc(100% - var(--left-nav-bar))" }}>
              {/* <AppNavbar></AppNavbar> */}
              <Switch>
                {/* <Route path="/reports">
                <Reports />
              </Route> */}
                {/* <Route path="/report/:id" component={Report} /> */}
                {/* <Route path="/news">
                <RegulationAlerts />
              </Route> */}
                {/* <Route path="/map">
                <Map />
              </Route> */}
                {/* <Route path="/upgrade">
                <NoSubscription />
              </Route> */}
                <Route path="/settings">
                  {/* eslint-disable-next-line */}
                  <Settings email={this.state.email} />
                </Route>
                {/* <Route path="/history">
                <ChangesHistory />
              </Route> */}
                {/* <Route path="/buynow">
                <BuyNow />
              </Route> */}
                {/* <Route path="/dashboard">
                  <Dashboard
                    reloadUserAccount={(e) => this.reloadUserAccount()}
                  />
                </Route> */}

                <Route path="/projects/:id/map">
                  <ProjectMapView />
                </Route>
                <Route path="/projects/:id/">
                  <ProjectView />
                </Route>
                <Route path="/projects">
                  <Projects />
                </Route>

                {/* <Route path="/browse">
                <Browse />
              </Route> */}
                {/* <Route path="/lists/:id" component={Properties} /> */}
                {/* <Route path="/properties/:id" component={Property} /> */}
                {/* <Route path="/closed_listings/:id" component={ClosedListings}/> */}
                {/* <Route path="/saved_listings" component={SavedListings}/> */}
                {/* <Route path="/saved_opportunities/:id" component={SavedOpportunities}/> */}
                {/* <Route path="/new_property"> */}
                {/* <PropertyForm /> */}
                {/* </Route> */}
                {/* <Route path="/invite">
                  <Invite accountUuid={this.state.accountUuid} />
                </Route> */}
                {/* <Route path="/reports">
                <DatabaseSearch />
              </Route> */}
                {/* <Route path="/listings">
                <Listings />
              </Route> */}
                {/* <Route exact path="/trackers">
                <Lists />
              </Route>
              <Route exact path="/billing">
                <Billing 
                  activeSubscription={this.state.activeSubscription}
                  trialExpired={this.state.trialExpired}
                  delinquant={false}
                  locked={false}
                  plan={this.state.plan}
                  last4={this.state.last4}
                />
              </Route>
              <Route exact path="/trackers/:id">
                <List />
              </Route>
              <Route exact path="/old_builder">
                <Builder />
              </Route>
              <Route exact path="/builder">
                <BuilderNew />
              </Route>
              <Route exact path="/regulations">
                <Regulations />
              </Route>
              <Route exact path="/explorer">
                <Explorer />
              </Route>
              <Route exact path="/regulations/new">
                <NewRegulationForm />
              </Route>
              <Route exact path="/regulations/:id">
                <Regulation />
              </Route> */}
                <Route path="/terms">
                  <StaticTerms />
                </Route>
                <Route path="/privacy">
                  <StaticPrivacy />
                </Route>
                <Route path="/dislaimer">
                  <StaticDislaimer />
                </Route>
                <Route path="/">
                  <Projects />
                </Route>
              </Switch>
            </div>
          </div>
        </HashRouter>
      </Provider>
    );
  }
}

export default ReactHome;
