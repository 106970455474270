import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import axios from "@/services/client";
import { updateFieldsOfCurrentLayer } from "@/store/project-slice";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import NewTableRow from "./NewTableRow";
import AddNewColumnMenu from "./AddNewColumnMenu";

function Table({ layerId, data, columns, isDragEnabled }) {
  const dispatch = useDispatch();
  const [rows, setRows] = useState(data);

  useEffect(() => setRows(data), [layerId, data]);

  const updateColumnColNumber = async (fieldId, newColNumber) => {
    try {
      const newFields = await axios.patch(`/api/fields/${fieldId}`, {
        field: { col_number: newColNumber },
      });
      dispatch(updateFieldsOfCurrentLayer({ newFields }));
    } catch (error) {
      toast.error(`Error re-arranging column: ${error.message}`);
    }
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const draggedRow = rows[dragIndex];
      const updatedRows = [...rows];

      if (draggedRow) {
        updatedRows.splice(dragIndex, 1);
        updatedRows.splice(hoverIndex, 0, draggedRow);
        setRows(updatedRows);
      }
    },
    [rows],
  );

  const moveColumn = useCallback(
    (dragIndex, hoverIndex) => {
      const draggedColumn = columns[dragIndex];
      if (draggedColumn) {
        updateColumnColNumber(draggedColumn.id, hoverIndex);
      }
    },
    [columns],
  );

  return (
    <table className="zonegoat-table">
      <thead>
        <tr>
          <th className="row-actions-th" />
          <th style={{ minWidth: 36 }} />
          {columns.map((column, index) => (
            <TableHeader
              key={`column-${column.id}`}
              column={column}
              moveColumn={moveColumn}
              index={index}
            />
          ))}
          <th className="px-2 w-100">
            <AddNewColumnMenu />
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(rows)
          .sort((a, b) => rows[a].row_number - rows[b].row_number)
          .map((key, index) => (
            <TableRow
              key={rows[key].id}
              rowItem={rows[key]}
              index={index}
              moveRow={moveRow}
              columns={columns}
              isDragEnabled={isDragEnabled}
            />
          ))}
        <NewTableRow
          layerId={layerId}
          totalColumns={columns.length}
          totalRows={rows.length}
        />
      </tbody>
    </table>
  );
}

function ZoneGoatTable(props) {
  return (
    <DndProvider backend={HTML5Backend}>
      <div
        style={{
          overflowX: "scroll",
          overflowY: "auto",
          paddingLeft: "calc(7.5% - var(--left-nav-bar) + -6px)",
          paddingRight: "7.5%",
          paddingBottom: "3rem",
          height: "100vh",
        }}
      >
        <Table {...props} isDragEnabled={false} />
      </div>
    </DndProvider>
  );
}

export default ZoneGoatTable;
