import _ from "lodash";
import {
  defaultLayerMarkerURL,
  customEmojisIds,
  URL_REGEX,
} from "../constants";

export function formatDate(inputDate) {
  const date = new Date(inputDate);
  const options = { month: "numeric", day: "numeric", year: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
}

export const saveValToObjectKey = (obj, key, val) => {
  if (obj[key]) {
    // eslint-disable-next-line no-param-reassign
    obj[key] = [...obj[key], val];
  } else {
    // eslint-disable-next-line no-param-reassign
    obj[key] = [val];
  }
};

export const removeObjectFromGoogleMap = (allObjects, key) => {
  if (!allObjects[key]) {
    return;
  }
  const allObjectsToRemove = allObjects[key];

  allObjectsToRemove.forEach((marker) => {
    marker.setMap(null);
  });
  // eslint-disable-next-line no-param-reassign
  delete allObjects[key];
};

export const yupValidate = (schema, data) => {
  try {
    schema.validateSync(data, { abortEarly: false });
    return { errors: [], valid: true };
  } catch (error) {
    return {
      errors: error.inner.map((validationError) => validationError.message),
      valid: false,
    };
  }
};

export const findNextIndex = (arr, currentIndex) => {
  const lastIndex = arr.length - 1;

  if (arr.length === 0) {
    return -1;
  }

  let indexToCheck = currentIndex < 0 ? 0 : currentIndex;
  indexToCheck = indexToCheck > lastIndex ? lastIndex : indexToCheck;

  const nextIndex =
    indexToCheck < lastIndex ? indexToCheck + 1 : indexToCheck - 1;

  return nextIndex;
};

export const getIconURL = (icon) => {
  if (icon) {
    if (customEmojisIds.includes(icon)) {
      return `/${icon}.svg`;
    }
    const codePoints = Array.from(icon).map((char) =>
      char.codePointAt(0).toString(16),
    );
    const unicodeString = codePoints.join("-");
    return `https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${unicodeString}.png`;
  }
  return defaultLayerMarkerURL;
};

export const parseOptions = (column) => {
  try {
    return JSON.parse(column.configs).options || [];
  } catch (err) {
    return [];
  }
};

export const parseConfigs = (column) => {
  try {
    return JSON.parse(column.configs) || {};
  } catch (err) {
    return {};
  }
};

export const isValidUrl = (url) => {
  let testURL = url;
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    testURL = `http://${url}`;
  }
  return URL_REGEX.test(testURL);
};

export const formatNumber = (str, format = "NUMBER") => {
  if (!str || str === "") {
    return "";
  }
  const value = parseFloat(str);
  const currencyOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  switch (format) {
    case "NUMBER":
      return `${Math.round(value * 100) / 100}`;
    case "NUMBER_WITH_COMMAS":
      return `${value.toLocaleString()}`;
    case "PERCENTAGE":
      return `${Math.round(value * 100) / 100}%`;
    case "US_DOLLARS":
      return `$${value.toLocaleString("en-US", currencyOptions)}`;
    case "CANADIAN_DOLLARS":
      return `$CA${value.toLocaleString("en-US", currencyOptions)}`;
    case "AUSTRALIAN_DOLLARS":
      return `$A${value.toLocaleString("en-US", currencyOptions)}`;
    case "SINGAPOOR_DOLLARS":
      return `SGD ${value.toLocaleString("en-US", currencyOptions)}`;
    case "DANISH_KRONE":
      return `DKK ${value.toLocaleString("en-US", currencyOptions)}`;
    case "KORNA":
      return `CZK ${value.toLocaleString("en-US", currencyOptions)}`;
    case "NORWEGIAN_KRONE":
      return `NOK ${value.toLocaleString("en-US", currencyOptions)}`;
    case "HONKONG_DOALLRS":
      return `HK$${value.toLocaleString("en-US", currencyOptions)}`;
    case "EURO":
      return `€${value.toLocaleString("en-US", currencyOptions)}`;
    case "MEXICAN_PESOS":
      return `MX$${value.toLocaleString("en-US", currencyOptions)}`;
    case "PHILIPINES_PESOS":
      return `₱${value.toLocaleString("en-US", currencyOptions)}`;
    case "POUND":
      return `£${value.toLocaleString("en-US", currencyOptions)}`;
    case "RUBLE":
      return `RUB ${value.toLocaleString("en-US", currencyOptions)}`;
    case "RUPEE":
      return `₹${value.toLocaleString("en-US", currencyOptions)}`;
    case "YUAN":
      return `CN¥${value.toLocaleString("en-US", currencyOptions)}`;
    case "YEN":
      return `¥${value.toLocaleString("en-US", currencyOptions)}`;
    case "WON":
      return `W${value.toLocaleString("en-US", currencyOptions)}`;
    default:
      return `${value}`;
  }
};

export const calculateMedian = (numbers) => {
  const sortedNumbers = _.sortBy(numbers);
  // Calculate median
  let median = null;
  if (sortedNumbers.length % 2 === 0) {
    const mid = sortedNumbers.length / 2;
    median = (sortedNumbers[mid - 1] + sortedNumbers[mid]) / 2;
  } else {
    const mid = Math.floor(sortedNumbers.length / 2);
    median = sortedNumbers[mid];
  }
  return median;
};
