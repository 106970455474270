import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import ZGButton from "../ZGButton";

function PointOfInterest({ poi, handlePOIDeleted }) {
  const [loading, setLoading] = useState(false);

  const handleDeleteClick = async () => {
    try {
      setLoading(true);
      const response = await fetch(`api/point_rules/${poi.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setLoading(false);
        handlePOIDeleted(poi.id);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex p-2 mb-2 justify-content-between align-items-center border rounded">
      <div className="d-flex align-items-center">
        <h5 className="mb-0 mr-2 text-danger">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </h5>
        <span className="text-danger">{poi.place_type}</span>
      </div>
      <div className="d-flex align-items-center">
        <p className="mb-0 mr-2">&#128664; {poi.duration_to_reach_place}</p>
        <ZGButton
          className="btn btn-sm btn-icon"
          loading={loading}
          onClick={handleDeleteClick}
        >
          <FontAwesomeIcon className="text-danger" icon={faTimesCircle} />
        </ZGButton>
      </div>
    </div>
  );
}

export default PointOfInterest;
