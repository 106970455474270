/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { parseConfigs, formatNumber } from "@/helpers/helpers";
import { STATS_TYPES, FIELD_TYPE_NAMES } from "@/constants";
import Switch from "./Switch";
import { updateFieldApi } from "../../../store/project-slice";

function FieldStatPoint({ field }) {
  const configs = parseConfigs(field);
  const statsValue = () => {
    switch (field.stats_type) {
      case "sum_type":
        return formatNumber(field.sum, configs.format);
      case "avg_type":
        return formatNumber(field.avg, configs.format);
      case "min_type":
        return formatNumber(field.min, configs.format);
      case "max_type":
        return formatNumber(field.max, configs.format);
      case "median_type":
        return formatNumber(field.median, configs.format);
      case "range_type":
        return `${formatNumber(field.min, configs.format)}-${formatNumber(field.max, configs.format)}`;
      default:
        return formatNumber(field.sum, configs.format);
    }
  };

  const statsDetail = () => {
    switch (field.stats_type) {
      case "sum_type":
        return `${field.name} Sum`;
      case "avg_type":
        return `Average ${field.name}`;
      case "min_type":
        return `${field.name} Min`;
      case "max_type":
        return `${field.name} Max`;
      case "median_type":
        return `Median ${field.name}`;
      case "range_type":
        return `${field.name} Range`;
      default:
        return field.name;
    }
  };

  return (
    <div className="field-stat-point">
      <h1 className="number fw-semibold">{statsValue()}</h1>
      <span className="number-label">{statsDetail()}</span>
    </div>
  );
}

function FieldStatPointEdit({ field }) {
  const dispatch = useDispatch();
  const [statsType, setStatsType] = useState(field.stats_type || "sum_type");

  const handleSwitchClick = (checked) => {
    dispatch(updateFieldApi({ id: field.id, show_stats: checked }));
  };

  useEffect(() => {
    dispatch(updateFieldApi({ id: field.id, stats_type: statsType }));
  }, [statsType]);

  return (
    <div className="field-stat-point">
      <label className="select-label" htmlFor="stat-type-select">
        {field.name}
      </label>
      <div className="d-flex justify-content-between align-content-center">
        <select
          className="form-select"
          id="stat-type-select"
          value={statsType}
          onChange={(e) => {
            setStatsType(e.target.value);
          }}
        >
          {Object.keys(STATS_TYPES).map((key) => (
            <option
              value={key}
              key={`stat-type-select-option-${field?.id}-${key}`}
            >
              {STATS_TYPES[key]}
            </option>
          ))}
        </select>
        <Switch
          color="success"
          checked={field.show_stats}
          onChange={(checked) => handleSwitchClick(checked)}
          containerClass="switch-container"
        />
      </div>
    </div>
  );
}

function MapLayerStatsControl() {
  const [settingsMode, setSettingsMode] = useState(false);
  const [orderedColumns, setOrderedColumns] = useState([]);

  const NumberFieldType = useSelector(
    (state) =>
      state.root.fieldTypes.filter(
        (fieldType) => fieldType.name === FIELD_TYPE_NAMES.NUMBER,
      )[0],
  );
  const activeLayer = useSelector((state) =>
    state.project.activeLayerIndex !== null
      ? state.project.layers[state.project.activeLayerIndex]
      : null,
  );

  useEffect(() => {
    const newOrderedColumns = [...activeLayer.fields]
      .filter((field) => field.field_type_id === NumberFieldType.id)
      .sort((a, b) => a.col_number - b.col_number);
    setOrderedColumns(newOrderedColumns);
  }, [NumberFieldType.id, activeLayer.fields]);

  return (
    <div className="layer-stats-control">
      <div className="layer-stats-control-header">
        <div>
          <h6 className="fw-semibold">Stats Board</h6>
          <span>Key Statistics Summary</span>
        </div>
        <div>
          {settingsMode ? (
            <button
              className="btn btn-success btn-sm save-settings-btn"
              type="button"
              onClick={() => setSettingsMode(false)}
            >
              Done
            </button>
          ) : (
            <button
              className="btn btn-icon"
              type="button"
              onClick={() => setSettingsMode(true)}
            >
              <FontAwesomeIcon icon={faGear} />
            </button>
          )}
        </div>
      </div>
      {((orderedColumns.filter((field) => field.show_stats).length === 0 &&
        !settingsMode) ||
        orderedColumns.length === 0) && (
        <div className="layer-stats-control-body-empty">Empty</div>
      )}
      <div className="layer-stats-control-body">
        {settingsMode ? (
          <>
            {orderedColumns.map((field) => (
              <FieldStatPointEdit
                key={`layer-stats-edit-${field.id}`}
                field={field}
              />
            ))}
          </>
        ) : (
          <>
            {orderedColumns
              .filter((field) => field.show_stats)
              .map((field) => (
                <FieldStatPoint key={`layer-stats-${field.id}`} field={field} />
              ))}
          </>
        )}
      </div>
    </div>
  );
}

export default MapLayerStatsControl;
