import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import axios from "@/services/client";
import { setProject, clearState } from "@/store/project-slice";
import { setMarkerIds, setMapCenter } from "@/store/root-slice";
import Switch from "./layers/Switch";
import NotFound from "../errors/NotFound";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css";
import ProjectLayersTabs from "./layers/ProjectLayersTabs";
import ProjectMap from "./map/ProjectMap";
import MapSearchField from "./map/MapSearchField";
import ToggleStatsBoardButton from "./map/ToggleStatsBoardButton";

function ProjectView() {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const [error, setError] = useState(null);
  const params = useParams();
  const [mapMode, setMapMode] = useState(false);
  const projectId = params.id;

  const fetchProject = async () => {
    try {
      const newProject = await axios.get(`/api/projects/${projectId}`);
      dispatch(setProject({ ...newProject }));
    } catch (err) {
      if (err.response?.status === 404) {
        setError(err.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const onSearch = (result) => {
    dispatch(
      setMarkerIds({
        markerLayerPointId: result.layerPointId,
        markerLayerIndex: result.layerIndex,
      }),
    );
    const [lng, lat] = result.coordinates.split(", ");
    dispatch(setMapCenter({ lat: Number(lat), lng: Number(lng) }));
  };

  const onClearSearch = () => {
    dispatch(
      setMarkerIds({
        markerLayerPointId: null,
        markerLayerIndex: null,
      }),
    );
  };

  useEffect(() => {
    fetchProject();
    return () => {
      dispatch(clearState());
    };
  }, [projectId]);

  if (error) {
    return <NotFound />;
  }

  if (!project) {
    return (
      <div className="w-100 d-flex align-items-center justify-content-center h-100">
        Loading...
      </div>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        theme="dark"
        hideProgressBar
        closeOnClick
      />
      <div
        className={styles.projectTopBar}
        style={mapMode ? { boxShadow: "0 8px 9px -2px rgb(0 0 0 / 0.2)" } : {}}
      >
        <div
          className="d-flex justify-content-between p-2 align-items-center position-relative"
          style={{ height: "var(--top-bar-height)" }}
        >
          <div className="d-flex align-items-center">
            {mapMode && (
              <>
                <MapSearchField
                  onSearch={onSearch}
                  onClearSearch={onClearSearch}
                />
                <div className="ml-3">
                  <ToggleStatsBoardButton />
                </div>
              </>
            )}
          </div>
          <div className="absolutly-centered">
            <h6 className="mb-0 fw-bold" style={{ fontSize: 18 }}>
              {!mapMode && <span>{project.name}</span>}
              {mapMode && project.layers.length && (
                <span hidden className="ml-2" style={{ color: "#1E8DF2" }}>
                  ({project.layers[project.activeLayerIndex].name})
                </span>
              )}
            </h6>
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "7.5%" }}
          >
            <div className="d-flex align-items-center">
              <Switch
                color="success"
                checked={mapMode}
                className="dark"
                onChange={(value) => setMapMode(value)}
              />
              <div
                className={`${styles.switchText} ${mapMode ? "text-zonegoat-green" : "text-muted"} fw-semibold`}
              >
                Map
              </div>
            </div>
          </div>
        </div>
      </div>
      {mapMode ? <ProjectMap /> : <ProjectLayersTabs />}
    </>
  );
}

export default ProjectView;
