import React from "react";
import { useDispatch, useSelector } from "react-redux";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
// import {
//   faCompassDrafting,
//   faCodeCompare,
// } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import axios from "@/services/client";
import { addLayer, setActiveLayerIndex } from "@/store/project-slice";

function NewLayerTab() {
  // const [showZoneAPIMenu, setShowZoneAPIMenu] = useState(false);
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);

  const createEmptyLayer = async () => {
    try {
      const response = await axios.post("/api/layers", {
        project_id: project.id,
        name: `Layer ${project.layers.length + 1}`,
        layer_type: "point_layer",
      });

      dispatch(addLayer(response));
      dispatch(setActiveLayerIndex(project.layers.length));
    } catch (error) {
      toast.error(`Error creating empty layer: ${error.message}`);
    }
  };

  return (
    <NavDropdown title="+" id="nav-dropdown" className="zonegoat-dropdown lg">
      <NavDropdown.Item
        className="has-icon"
        onClick={createEmptyLayer}
        style={{ width: 275 }}
      >
        <FontAwesomeIcon icon={faFile} /> <span>Empty Layer</span>
      </NavDropdown.Item>
      {/* 
      <NavDropdown.Divider
        style={{
          paddingRight: "14rem",
          marginRight: "2.5rem",
          marginLeft: "0.75rem",
        }}
      /> */}

      {/* <NavDropdown.Item className="has-icon" disabled>
        <small>
          <FontAwesomeIcon icon={faCompassDrafting} /> <b>Strategic zones</b>
        </small>
      </NavDropdown.Item>

      <NavDropdown.Item hidden>Triangulated POI Zone</NavDropdown.Item>
      <NavDropdown.Item hidden>Custom Criteria Zones</NavDropdown.Item>

      <NavDropdown.Item className="has-icon" disabled hidden>
        <small>
          <FontAwesomeIcon icon={faCodeCompare} /> <b>Integration</b>
        </small>
      </NavDropdown.Item>

      <NavDropdown
        show={showZoneAPIMenu}
        title="Zone API"
        id="zone-api-sub-dropdown"
        className="sub-dropdown"
        onMouseEnter={() => setShowZoneAPIMenu(true)}
        onMouseLeave={() => setShowZoneAPIMenu(false)}
        hidden
      >
        <NavDropdown.Item className="has-icon">Zoning Zones</NavDropdown.Item>
        <NavDropdown.Item className="has-icon">Parcels</NavDropdown.Item>
      </NavDropdown>

      <NavDropdown.Item>Population Density API</NavDropdown.Item>
      <NavDropdown.Item>Crime Rate API</NavDropdown.Item>
      <NavDropdown.Item>US Neighborhood API</NavDropdown.Item> */}
    </NavDropdown>
  );
}

export default NewLayerTab;
