import React, { useState, useEffect } from "react";
import { FIELD_TYPE_NAMES } from "@/constants";
import { parseConfigs } from "@/helpers/helpers";
import { updateFieldApi } from "@/store/project-slice";
import { useDispatch } from "react-redux";
import AddressInput from "./AddressInput";
import TextInput from "../../TextInput";
import SelectInput from "./SelectInput";
import DateInput from "./DateInput";

function CellInput({
  fieldType,
  onEditingEnded,
  cell,
  column,
  row,
  updateCell,
  addNewCell,
}) {
  const [configs, setConfigs] = useState();
  const [selectOptions, setSelectOptions] = useState(configs?.options || []);
  const dispatch = useDispatch();

  useEffect(() => {
    setConfigs(parseConfigs(column));
  }, [column.configs]);

  useEffect(() => {
    setSelectOptions(configs?.options || []);
  }, [configs]);

  const handleAddressSelected = (location) => {
    const coordinateColId = column.derived_fields[0].id;
    if (cell.id) {
      updateCell(cell.id, location.address);
      updateCell(
        row.cells[coordinateColId].id,
        [location.longitude, location.latitude].join(", "),
      );
    } else {
      addNewCell(column.id, location.address);
      addNewCell(
        coordinateColId,
        [location.longitude, location.latitude].join(", "),
      );
    }
  };

  const onAddressFieldBlur = () => {
    onEditingEnded();
  };

  const handleKeyDown = (event) => {
    if (["Enter", "Escape"].includes(event.key)) {
      onEditingEnded();
    }
  };

  const extractAndFormatNumber = (inputString) => {
    // Use regular expression to extract the number from the input string
    const numberString = inputString.match(/[\d.]+/g)?.join("");
    if (!numberString || numberString === "") {
      return "";
    }
    const number = parseFloat(numberString);

    return number;
  };

  const onValueConfirm = (value, skipFocusChange = false) => {
    let valueToSave = value;
    if (fieldType.name === FIELD_TYPE_NAMES.NUMBER) {
      valueToSave = extractAndFormatNumber(valueToSave);
    }

    if (cell.value !== valueToSave) {
      if (cell.id) {
        updateCell(cell.id, valueToSave, skipFocusChange);
      } else {
        addNewCell(column.id, valueToSave, skipFocusChange);
      }
    }
    onEditingEnded();
  };

  const handleOptionCreate = async (newOption) => {
    const newConfigs = JSON.stringify({
      options: [...selectOptions, newOption],
    });
    dispatch(updateFieldApi({ configs: newConfigs, id: column.id })).then(() =>
      onValueConfirm(newOption),
    );
  };

  if (fieldType.name === FIELD_TYPE_NAMES.ADDRESS) {
    return (
      <AddressInput
        onLocationSelected={handleAddressSelected}
        onBlur={onAddressFieldBlur}
        onKeyDown={handleKeyDown}
        value={cell.value}
      />
    );
  }
  if (fieldType.name === FIELD_TYPE_NAMES.SELECT) {
    return (
      <SelectInput
        row={row}
        column={column}
        options={selectOptions}
        value={cell.value}
        onSelected={onValueConfirm}
        onCreate={handleOptionCreate}
        onEditingEnded={onEditingEnded}
      />
    );
  }
  if (
    [
      FIELD_TYPE_NAMES.TEXT,
      FIELD_TYPE_NAMES.URL,
      FIELD_TYPE_NAMES.NUMBER,
    ].includes(fieldType.name)
  ) {
    return (
      <TextInput
        className={`celled-input ${fieldType.name === FIELD_TYPE_NAMES.NUMBER && "text-right pr-1"}`}
        required={false}
        onConfirm={onValueConfirm}
        value={cell.value}
        type={fieldType.name}
        sm={false}
        showError={false}
      />
    );
  }
  if (fieldType.name === FIELD_TYPE_NAMES.CHECKBOX) {
    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label
        className="w-100 h-100 d-flex justify-content-center mb-0"
        onBlur={onEditingEnded}
      >
        <input
          type="checkbox"
          checked={cell.value === "true"}
          onChange={(e) => {
            onValueConfirm(e.target.checked ? "true" : "false", true);
          }}
        />
      </label>
    );
  }
  if (fieldType.name === FIELD_TYPE_NAMES.DATE) {
    return (
      <DateInput
        column={column}
        row={row}
        value={cell.value}
        onSelected={onValueConfirm}
        onEditingEnded={onEditingEnded}
      />
    );
  }
  return "";
}

export default CellInput;
