/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { parseOptions } from "@/helpers/helpers";
import { useSelector } from "react-redux";
import { FIELD_TYPE_NAMES } from "@/constants";

function SelectFieldFilter({ field, onChange = () => {}, handleToggle }) {
  const options = parseOptions(field);
  const fitler = useSelector((state) => state.root.filters[field.id]);

  const [selectedOption, setSelectedOption] = useState(
    fitler?.selected[0] || "",
  );

  const hasFiveOrLessOptions = options.length <= 5;
  const everyOptionsHasLessThan3Chars = options.every((o) => o.length < 3);

  const clearFilter = () => {
    onChange({
      key: field.id,
      value: undefined,
    });
    setSelectedOption("");
  };

  const onSelected = (option) => {
    if (option !== "") {
      onChange({
        key: field.id,
        value: { type: FIELD_TYPE_NAMES.SELECT, selected: [option] },
      });
    } else {
      clearFilter();
    }
    handleToggle();
  };

  const handleSelected = (value) => {
    onSelected(value);
    setSelectedOption(value);
  };

  let optionsElement = (
    <select
      className="form-select"
      style={{ borderColor: "var(--zonegaot-grey-)" }}
      value={selectedOption}
      onChange={(event) => handleSelected(event.target.value)}
    >
      <option value="">Any</option>
      {options.map((option) => (
        <option
          key={`select-filter-${field.id}-option-${option}`}
          value={option}
        >
          {option}
        </option>
      ))}
    </select>
  );

  if (hasFiveOrLessOptions && everyOptionsHasLessThan3Chars) {
    optionsElement = (
      <div className="select-field-filter-checkbox-group">
        <span className="checkbox-span">
          <input
            type="radio"
            className={`btn-check `}
            name="options"
            id="any-option"
            onChange={() => handleSelected("")}
            checked={selectedOption === ""}
          />
          <label
            className={`btn btn-sm checkbox-label ${selectedOption === "" ? "selected" : ""}`}
            htmlFor="any-option"
          >
            Any
          </label>
        </span>
        {options.sort().map((option) => (
          <span
            className="checkbox-span"
            key={`select-filter-${field.id}-option-${option}`}
          >
            <input
              type="radio"
              className={`btn-check `}
              name="options"
              id={`select-filter-${field.id}-option-${option}`}
              checked={selectedOption === option}
              onChange={() => handleSelected(option)}
            />
            <label
              className={`btn btn-sm checkbox-label ${selectedOption === option ? "selected" : ""}`}
              htmlFor={`select-filter-${field.id}-option-${option}`}
            >
              {option}
            </label>
          </span>
        ))}
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <p className="text-zonegoat-gray-1 mb-0">{field.name}</p>
        <button
          type="button"
          className="btn btn-sm btn-text text-zonegoat-primary"
          onClick={clearFilter}
        >
          Clear
        </button>
      </div>
      <div className="my-2">{optionsElement}</div>
    </div>
  );
}

export default SelectFieldFilter;
