import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import NotFound from "../errors/NotFound";
import ProjectMap from "./map/ProjectMap";
import "react-toastify/dist/ReactToastify.css";

function ProjectMapView() {
  const [project, setProject] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const history = useHistory();
  const projectId = params.id;

  const fetchProjectDetails = async () => {
    const response = await fetch(`/api/projects/${projectId}`);
    if (!response.ok) {
      throw new Error("Project not found");
    }
    const projectData = await response.json();
    return projectData;
  };

  const refreshProject = () => {
    fetchProjectDetails()
      .then((projectData) => {
        return setProject(() => {
          const updatedProject = { ...projectData };
          return updatedProject;
        });
      })
      .catch((err) => setError(err.message));
  };

  useEffect(() => {
    refreshProject();
  }, [projectId]);

  if (error) {
    return <NotFound />;
  }

  if (!project) {
    return (
      <div className="w-100 d-flex align-items-center justify-content-center h-100">
        Loading...
      </div>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        theme="dark"
        hideProgressBar
        closeOnClick
      />
      <div className="w-100 d-flex justify-content-between p-2 align-items-center">
        <div>
          <button
            className="btn btn-icon"
            type="button"
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </div>
        <div>
          <b>{project.name}</b>
        </div>
        <div />
      </div>
      <ProjectMap project={project} />
    </>
  );
}

export default ProjectMapView;
