import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPlusCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import styles from "./styles.module.css";

function AnchorPointField({
  project,
  handleSelectedLocation,
  handleAnchorPointAdded,
}) {
  const [latLng, setLatLng] = useState(null);
  const [address, setAddress] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (a) => {
    setAddress(a);
  };

  const handleSelect = (a) => {
    setAddress(a);
    geocodeByAddress(a)
      .then((results) => getLatLng(results[0]))
      .then((latitudeLongitude) => {
        setLatLng(latitudeLongitude);
        handleSelectedLocation({
          address: a,
          latitude: latitudeLongitude.lat,
          longitude: latitudeLongitude.lng,
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error("Error", error));
  };

  const handlePlusClick = async () => {
    const trimedAddress = address.trim();
    if (!trimedAddress) {
      return;
    }
    try {
      const anchorPointPayload = {
        anchor_point: {
          address: trimedAddress,
          latitude: latLng.lat,
          longitude: latLng.lng,
          project_id: project.id,
        },
      };
      setSubmitting(true);
      const response = await fetch("/api/anchor_points", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(anchorPointPayload),
      });

      if (!response.ok) {
        throw new Error(`Failed to add anchor point: ${response.statusText}`);
      }

      const newAnchorPoint = await response.json();

      handleAnchorPointAdded(newAnchorPoint);
      setSubmitting(false);
      setAddress("");
    } catch (error) {
      setSubmitting(false);
      // eslint-disable-next-line no-console
      console.error("Error adding anchor point:", error.message);
    }
  };

  return (
    <div className={styles.anchorPointFieldContainer}>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <div className="input-group">
              <span
                className={`input-group-text ${styles.anchorPointFieldLocationMark}`}
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </span>
              <input
                {...getInputProps({
                  placeholder: "Add an anchor point",
                  className: `form-control form-control-lg location-search-input ${styles.achorPointInput}`,
                })}
              />
              <button
                className={styles.anchorPointFieldButton}
                type="button"
                disabled={loading || submitting}
                onClick={handlePlusClick}
                style={{ cursor: loading || submitting ? "wait" : "pointer" }}
              >
                {loading || submitting ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <FontAwesomeIcon icon={faPlusCircle} />
                )}
              </button>
            </div>
            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={`address-suggestion-${suggestion.placeId}`}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </div>
  );
}

export default AnchorPointField;
