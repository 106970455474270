import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ZGButton from "@/components/ZGButton";
import axios from "@/services/client";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchLayer } from "@/store/project-slice";

function AddInPlaceRowButton({ rowActionStyle, row }) {
  const dispatch = useDispatch();
  const handleAddRow = async () => {
    try {
      const payload = {
        layer_point: { layer_id: row.layer_id, row_number: row.row_number + 1 },
      };
      await axios.post("/api/layer_points", payload);
      dispatch(fetchLayer(row.layer_id));
    } catch (error) {
      toast.error("Error adding row:", error.message);
    }
  };

  return (
    <ZGButton className="btn btn-icon pr-1 btn-sm" onClick={handleAddRow}>
      <FontAwesomeIcon
        icon={faPlus}
        className="text-secondary"
        style={rowActionStyle}
      />
    </ZGButton>
  );
}

export default AddInPlaceRowButton;
