/* global localStorage */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faUserCog,
  faDoorOpen,
  faCodeCompare,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

class SideNavbar extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  // eslint-disable-next-line
  clearSessionTokens() {
    localStorage.removeItem("zonegoat_account_uuid");
    localStorage.removeItem("zonegoat_user_uuid");
  }

  render() {
    return (
      <>
        {/* eslint-disable-next-line */}
        <a
          className="navbar-brand text-white ml-0 mr-0 fw-bold"
          style={{ fontSize: "14px", padding: 14, letterSpacing: 1 }}
          href="#"
        >
          ZONE
          <br />
          GOAT
        </a>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link to="/projects" className="nav-link text-white text-center">
              <FontAwesomeIcon icon={faFolder} style={{ fontSize: 30 }} />
            </Link>
          </li>

          <li className="nav-item" hidden>
            {/* eslint-disable-next-line */}
            <a href="#" className="nav-link text-white text-center">
              <FontAwesomeIcon icon={faCodeCompare} size="xl" />
            </a>
          </li>
        </ul>

        <ul className="nav flex-column mb-1">
          <li className="nav-item" hidden>
            <Link to="/settings" className="nav-link text-white text-center">
              <FontAwesomeIcon icon={faUserCog} size="xl" />
            </Link>
          </li>

          <li className="nav-item">
            <a
              href="/logout"
              className="nav-link text-white text-center"
              onClick={(e) => this.clearSessionTokens(e)}
            >
              <FontAwesomeIcon icon={faDoorOpen} size="xl" />
            </a>
          </li>
        </ul>
      </>
    );
  }
}

export default SideNavbar;
