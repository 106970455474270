import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMarkerIds } from "@/store/root-slice";
import LayerIcon from "./LayerIcon";
import { formatNumber, parseConfigs } from "../../../helpers/helpers";

const MAX_CHARACTERS = 22;

function Marker({ icon, layerPointId, layerIndex }) {
  const dispatch = useDispatch();
  const markerLayerPointId = useSelector(
    (state) => state.root.markerLayerPointId,
  );
  const layer = useSelector((state) => state.project.layers[layerIndex]);
  const value =
    layer.layer_points[layerPointId].cells[layer.text_marker_id]?.value ||
    "N/A";
  const column = layer.fields.find(
    (field) => field.id === layer.text_marker_id,
  );
  const configs = parseConfigs(column);

  const formattedValue = formatNumber(
    value.length > MAX_CHARACTERS
      ? `${value.slice(0, MAX_CHARACTERS)}...`
      : value,
    configs.format,
  );

  const onClick = () => {
    dispatch(
      setMarkerIds({
        markerLayerPointId: layerPointId,
        markerLayerIndex: layerIndex,
      }),
    );
  };

  return (
    <button
      type="button"
      className={`btn btn-icon map-marker ${layer.text_marker_id ? "text" : "emoji"} ${markerLayerPointId === layerPointId ? "active" : ""}`}
      onClick={onClick}
    >
      {layer.text_marker_id ? (
        formattedValue
      ) : (
        <LayerIcon
          icon={icon}
          style={{ width: 22, marginLeft: -9, marginTop: -10 }}
        />
      )}
    </button>
  );
}

export default Marker;
