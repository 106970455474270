/* eslint-disable */

import React from "react";

function Settings() {
  return (
    <div className="row pl-4 pr-4">
      <div className="col">
        <div className="card">
          <div className="card-header">Settings</div>
          <div className="card-body">
            <div className="card text-center">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs">
                  <li classsName="nav-item">
                    <a className="nav-link active" href="#">
                      Organization
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body mb-6">
                <div className="container">
                  <div className="col-12 text-left">
                    <form>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Billing Email Address
                        </label>
                        <input
                          value={this.props.email}
                          type="email"
                          className="form-control col-4"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                        <small id="emailHelp" className="form-text text-muted">
                          Bills will be sent to that email
                        </small>
                      </div>
                    </form>
                    <p>
                      The admin for this organization is{" "}
                      <b>John Doe (john@doe.com)</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="card text-center">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#">
                      Your Personal Information
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body mb-6">
                <div className="container">
                  <div className="col-12 text-left">
                    <form>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">First Name</label>
                        <input
                          value={this.props.first_name}
                          type="text"
                          className="form-control col-4"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Last Name</label>
                        <input
                          value={this.props.first_name}
                          type="text"
                          className="form-control col-4"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Email Address
                        </label>
                        <input
                          value={this.props.email}
                          type="email"
                          className="form-control col-4"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                        <small id="emailHelp" className="form-text text-muted">
                          Your email address is your identity on Zonegoat and is
                          used to log in..
                        </small>
                      </div>

                      <div className="form-group mb-4">
                        <label htmlFor="exampleInputPassword1">
                          Change Password
                        </label>
                        <p>
                          To change your password, logout then click on 'Forgot
                          your password?' on the login page.
                        </p>
                      </div>
                      {/* <button type="submit" className="btn btn-primary" disabled>Update Password</button> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
